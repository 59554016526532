/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  Checkbox,
  CircularProgress,
  FormControl,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styles from '../FilterPanel.module.scss';
import Text from '../../typographies/Text/Text';

/**
 * FilterOption component needs to display different options for filtering.
 *
 * @example
 * <FilterOption
 *   iconPath="icon path"
 *   title="Title"
 *   propertyName='name'
 *   options={[]}
 *   isLoading={isLoading}
 *   properties={properties}
 *   setProperties={setProperties}
 * />
 */

const MenuProps = {
  PaperProps: {
    className: styles.selectMenu
  }
};

type TOptions = { id: number; name: string };
type TValue = number | number[] | undefined;

interface IFilterOptionProps {
  iconPath: string;
  title: string;
  options: TOptions[];
  isLoading: boolean;
  value: TValue;
  onChange: (value: any) => void;
  multiple?: boolean;
}

const FilterOption: React.FC<IFilterOptionProps> = ({
  iconPath,
  title,
  options,
  isLoading,
  value,
  onChange,
  multiple
}) => {
  const isMultipleValue = multiple && Array.isArray(value);

  const handleChange = (event: SelectChangeEvent<number[]>) => {
    const {
      target: { value }
    } = event;

    onChange(value);
  };

  const clearProperty = () => {
    onChange(undefined);
  };

  const selectedValue = value ? (Array.isArray(value) ? value : [value]) : [];

  const renderValue = (selected: number[]) => {
    const selectedNames = selected
      .map((val) => {
        const selectedValue = options?.find((option) => option.id === val);
        return selectedValue?.name;
      })
      .filter((selectedBlock) => selectedBlock);

    return selectedNames.length
      ? selectedNames.join(', ')
      : 'Select property...';
  };

  const displayDeleteButton =
    (isMultipleValue && value?.length) || typeof value === 'number';

  return (
    <div className={styles.filterOption}>
      <img src={iconPath} alt={title} />
      <div>
        <Text type="label" className={styles.title}>
          {title}
        </Text>
        <FormControl
          className={`${styles.formControl} ${!displayDeleteButton && styles.fullWidth}`}
        >
          <Select
            fullWidth
            displayEmpty
            multiple={multiple}
            value={selectedValue}
            onChange={handleChange}
            input={
              <OutlinedInput
                classes={{ notchedOutline: styles.notchedOutline }}
              />
            }
            inputProps={{ className: styles.input }}
            className={styles.select}
            MenuProps={MenuProps}
            renderValue={renderValue}
          >
            {isLoading ? (
              <MenuItem disabled>
                <CircularProgress size={24} />
              </MenuItem>
            ) : (
              options?.map((prop) => (
                <MenuItem
                  key={prop.id}
                  value={prop.id}
                  className={styles.menuItem}
                >
                  {isMultipleValue ? (
                    <Checkbox
                      className={styles.checkbox}
                      checked={!!value?.find((i: number) => i === prop.id)}
                    />
                  ) : null}
                  {prop.name}
                </MenuItem>
              ))
            )}
          </Select>
          {displayDeleteButton ? (
            <IconButton onClick={clearProperty}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </FormControl>
      </div>
    </div>
  );
};

export default FilterOption;
