import React, { useState } from 'react';
import {
  getDefaultDateFilterRange,
  UTCFormat
} from '../../../utils/dateFormat';
import { IProperties } from '../../FilterPanel/RunsFilterPanel/interface';
import { dateFormatMonthDayYear } from '../../../constants/dateFormat';
import RunsFilterPanel from '../../FilterPanel/RunsFilterPanel/RunsFilterPanel';
import useRunsSummary from '../../../hooks/useRunsSummary';
import WidgetCard from '../../WidgetCard/WidgetCard';
import TableComponent from '../../Table';
import { IPaginationParams } from '../../../../types/api';
import { EReportName } from '../../../constants/reports';

const DEFAULT_PAGINATION_PARAMS: Required<IPaginationParams> = {
  skip: 0,
  take: 10
};

const RunsSummary = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [properties, setProperties] = useState<IProperties>({
    date: getDefaultDateFilterRange()
  });

  const { data, isLoading, total, error } = useRunsSummary({
    ...properties,
    take: DEFAULT_PAGINATION_PARAMS.take,
    skip:
      currentPage > 0 ? (currentPage - 1) * DEFAULT_PAGINATION_PARAMS.take : 0
  });

  const formattedRows = data?.map((row) => {
    const [month, day, year] = row.date.split('T')[0].split('-');
    const date = new Date(`${year}-${month}-${day}T${row.date.split('T')[1]}`);
    return {
      ...row,
      runUrl: `${process.env.REACT_APP_BASE_URL}/${row.runUrl}`,
      date: UTCFormat(date, dateFormatMonthDayYear),
      coverageAreaSprayedAcres: row.coverageAreaSprayedAcres.toFixed(1)
    };
  });

  const columns = [
    { id: 'date', label: 'Date' },
    { id: 'subLocationName', label: 'Block' },
    { id: 'tractorName', label: 'Vehicle' },
    { id: 'coverageAreaSprayedAcres', label: 'Covered acres' },
    { id: 'runUrl', label: '' }
  ];

  const totalAmount = total || 0;
  const totalRuns = `${totalAmount} run${totalAmount < 2 ? '' : 's'}`;
  const summary = `Displays a summary of the last ${totalRuns}.`;

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <WidgetCard
      isExport
      error={error}
      isEmpty={!data?.length}
      title={EReportName.RunSummary}
      icon="📋"
      description={summary}
      isLoading={isLoading}
      properties={properties}
      setProperties={setProperties}
      filter={
        <RunsFilterPanel
          properties={properties}
          setProperties={setProperties}
        />
      }
    >
      <TableComponent
        columns={columns}
        rows={formattedRows || []}
        totalPages={Math.ceil(totalAmount / DEFAULT_PAGINATION_PARAMS.take)}
        onPageChange={handlePageChange}
        currentPage={currentPage}
      />
    </WidgetCard>
  );
};

export default RunsSummary;
