import React from 'react';
import styles from './WidgetCard.module.scss';
import Text from '../typographies/Text/Text';

/**
 * EmptyState component needs to show empty state for widget when no data.
 *
 * @example
 *  <EmptyState />
 */

const EmptyState = () => {
  return (
    <div className={styles.state}>
      <div className={styles.infoContainer}>
        <img src="/images/filter_alt.svg" />
        <div>
          <Text type="label">Try different filters</Text>
          <Text type="label">
            No data available matching your filter criteria
          </Text>
        </div>
      </div>
    </div>
  );
};

export default EmptyState;
