import useSWR from 'swr';
import { UTCFormat } from '../utils/dateFormat';
import { CC_API_ENDPOINTS } from '../constants/api';
import {
  createQueryString,
  fetchSubLocationSprayedInfo
} from '../utils/api-utils';
import { dateFormatParams } from '../constants/dateFormat';
import { ISavingsPerBlockProperties } from '../components/FilterPanel/SavingsPerBlockFilterPanel/interface';

const useSavingsPerBlock = ({ date, block }: ISavingsPerBlockProperties) => {
  const formattedDateFrom = UTCFormat(date?.from, dateFormatParams);
  const formattedDateTo = UTCFormat(date?.to, dateFormatParams);

  const subLocationSprayedInfoParams = {
    dateFrom: formattedDateFrom,
    dateTo: formattedDateTo,
    subLocationIds: block?.map((i) => i.id)
  };

  const { data, isLoading, error } = useSWR(
    `${CC_API_ENDPOINTS.subLocationSprayedInfo}?${createQueryString(subLocationSprayedInfoParams)}`,
    () => fetchSubLocationSprayedInfo(subLocationSprayedInfoParams)
  );

  const { subLocationSprayedInfo } = data || {};

  return {
    data: subLocationSprayedInfo,
    isLoading,
    error
  };
};

export default useSavingsPerBlock;
