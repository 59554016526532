import React, { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import BoomIcon from '@mui/icons-material/Dehaze';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ShowerIcon from '@mui/icons-material/Shower';
import authService from '../../api-authorization/AuthorizeService';
import RequestOverlay from '../../components/RequestOverlay';
import styles from './AdminConfigTractors.module.css';
import AddTractorDialog from './AddTractorDialog/AddTractorDialog';
import UpdateTractorDialog from './UpdateTractorDialog.js/UpdateTractorDialog';
import AddDeviceDialog from './AddDeviceDialog/AddDeviceDialog';
import UpdateDeviceDialog from './UpdateDeviceDialog.js/UpdateDeviceDialog';

const AdminConfigTractors = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [clients, setClients] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [filter, setFilter] = useState('');

  const [isAddTractorDialogOpen, setIsAddTractorDialogOpen] = useState(false);
  const [isUpdateTractorDialogOpen, setIsUpdateTractorDialogOpen] =
    useState(false);
  const [isAddDeviceDialogOpen, setIsAddDeviceDialogOpen] = useState(false);
  const [isUpdateDeviceDialogOpen, setIsUpdateDeviceDialogOpen] =
    useState(false);

  const [tractorToUpdate, setTractorToUpdate] = useState(null);
  const [deviceToUpdate, setDeviceToUpdate] = useState(null);
  const [showTractorDevices, setShowTractorDevices] = useState({});
  const [tractorDevices, setTractorDevices] = useState({});

  const [locations, setLocations] = useState([]);
  const [locationIdsToNamesMap, setLocationIdsToNamesMap] = useState({});

  const [client, setClient] = useState('');
  const [tractors, setTractors] = useState([]);
  const [unaffiliatedDevices, setUnaffiliatedDevices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [, setError] = useState(false);

  const toggleShowTractorDevices = (tractor) => {
    setShowTractorDevices((prev) => ({
      ...prev,
      [tractor.id]: !prev[tractor.id]
    }));
    fetchTractorDevices(tractor);
  };

  const fetchTractorDevices = async (tractor) => {
    setLoading(true);
    try {
      const token = await authService.getAccessToken();
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/admin/devices/all-by-tractor?clientId=${selectedClient.id}&tractorId=${tractor.id}`,
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` }
        }
      );
      if (response.ok) {
        const deviceData = await response.json();
        setTractorDevices((prev) => ({ ...prev, [tractor.id]: deviceData }));
      } else {
        toast.error(`${response.status} ${response.statusText}`);
      }
    } catch (error) {
      setTimeout(() => {
        toast.error('An unexpected error occured! Please try again later!');
      }, 1000);
    }
  };

  const fetchLocationsAccordingToSelectedClient = async () => {
    setLoading(true);
    try {
      const token = await authService.getAccessToken();
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/admin/locations/all?clientId=${selectedClient.id}`,
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` }
        }
      );
      const locations = await response.json();
      setLocations(locations);
      setLocationIdsToNamesMap(() => {
        const idsToNames = {};
        locations.forEach(
          (location) => (idsToNames[location.id] = location.name)
        );
        return idsToNames;
      });
    } catch (error) {
      setTimeout(() => {
        toast.error('An unexpected error occured! Please try again later!');
      }, 1000);
    }
  };

  useEffect(() => {
    const fetchClients = async () => {
      setLoading(true);
      try {
        const token = await authService.getAccessToken();
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/api/admin/clients/all`,
          {
            headers: !token ? {} : { Authorization: `Bearer ${token}` }
          }
        );
        const clientData = await response.json();
        setClients(clientData);
        setLoading(false);
        setError(false);
        const client = clientData.find(
          (c) => c.id === parseInt(searchParams.get('clientId'), 10)
        );
        setSelectedClient(client ? client : clientData[0]);
      } catch (error) {
        console.log('error', error);
        setTimeout(() => {
          setError(true);
          setLoading(false);
        }, 1000);
      }
    };
    fetchClients();
  }, []);

  const fetchTractors = async () => {
    try {
      const token = await authService.getAccessToken();

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/admin/tractors/all?clientId=${selectedClient.id}`,
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` }
        }
      );
      const tractorData = await response.json();
      setTractors(tractorData);
    } catch (error) {        
      toast.error('Communication with server failed. Please reload your page.');
    }
  };

  const fetchUnaffiliatedDevices = async () => {
    try {
      const token = await authService.getAccessToken();

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/admin/devices/unaffiliated`,
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` }
        }
      );
      const unaffiliatedDevices = await response.json();
      setUnaffiliatedDevices(unaffiliatedDevices);
    } catch (error) {
      toast.error('Communication with server failed. Please reload your page.');
    }
  };

  useEffect(() => {    
    fetchUnaffiliatedDevices();
    if (selectedClient) {
      fetchTractors();
      fetchLocationsAccordingToSelectedClient();
    }
  }, [selectedClient]);

  useEffect(() => {
    if (selectedClient) {
      setSearchParams({ clientId: selectedClient.id });
    }
  }, [setSearchParams, selectedClient]);

  const handleClientSelectChange = (e) => {
    setSelectedClient(e.target.value);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const openAddTractorDialog = () => {
    setIsAddTractorDialogOpen(true);
  };

  const closeAddTractorDialog = () => {
    setIsAddTractorDialogOpen(false);
  };

  const handleAddTractorSuccess = () => {
    fetchTractors();
    closeAddTractorDialog();
  };

  const openUpdateTractorDialog = (tractor) => {
    setTractorToUpdate(tractor);
    setIsUpdateTractorDialogOpen(true);
  };

  const closeUpdateTractorDialog = () => {
    setTractorToUpdate(null);
    setIsUpdateTractorDialogOpen(false);
  };

  const handleUpdateTractorSuccess = () => {
    fetchTractors();
    closeUpdateTractorDialog();
  };

  const openAddDeviceDialog = (tractor) => {
    setTractorToUpdate(tractor);
    setIsAddDeviceDialogOpen(true);
  };

  const closeAddDeviceDialog = () => {
    setTractorToUpdate(null);
    setIsAddDeviceDialogOpen(false);
  };

  const handleAddDeviceSuccess = (tractor) => {
    closeAddDeviceDialog();
    fetchTractorDevices(tractor);
  };

  const openUpdateDeviceDialog = (tractor, device) => {
    setTractorToUpdate(tractor);
    setDeviceToUpdate(device);
    setIsUpdateDeviceDialogOpen(true);
  };

  const closeUpdateDeviceDialog = () => {
    setTractorToUpdate(null);
    setDeviceToUpdate(null);
    setIsUpdateDeviceDialogOpen(false);
  };

  const handleUpdateDeviceSuccess = (tractor) => {
    closeUpdateDeviceDialog();
    fetchTractorDevices(tractor);
  };

  return (
    <>
      <div className={styles.container}>
        <RequestOverlay
          isLoading={!loading}
          id="loading-overlay"
          errorTitle="Your data couldn't be loaded."
          errorSubtitle="Please contact an administrator."
        />
        <Typography variant="h4" sx={{ pb: 1 }}>
          Sprayer Management
        </Typography>
        {clients && (
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Select
                value={selectedClient}
                onChange={handleClientSelectChange}
                startAdornment={
                  <InputAdornment position="start">
                    <AccountCircleIcon />
                  </InputAdornment>
                }
              >
                {clients.map((client) => (
                  <MenuItem key={client.id} value={client}>
                    {client.name}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                placeholder="Filter"
                variant="outlined"
                onChange={handleFilterChange}
                value={filter}
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <FilterAltIcon />
                      </InputAdornment>
                    )
                  }
                }}
              />
            </Box>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={openAddTractorDialog}
            >
              Add Sprayer
            </Button>
          </Stack>
        )}
        <TableContainer component={Paper} sx={{ my: 1 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>ID</TableCell>
                <TableCell>Sprayer Name</TableCell>
                <TableCell>Default Location</TableCell>
                <TableCell>Boom Width (Ft)</TableCell>
                <TableCell>Current Spray Width (Ft)</TableCell>
                <TableCell>Boom Count</TableCell>
                <TableCell>Nozzle Count</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tractors
                .filter((tractor) =>
                  Object.values(tractor)
                    .join()
                    .toLowerCase()
                    .includes(filter.toLowerCase())
                )
                .map((tractor) => (
                  <React.Fragment key={tractor.id}>
                    <TableRow>
                      <TableCell>
                        <IconButton
                          onClick={() => toggleShowTractorDevices(tractor)}
                        >
                          {showTractorDevices[tractor.id] ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )}
                        </IconButton>
                      </TableCell>
                      <TableCell>{tractor.id}</TableCell>
                      <TableCell>{tractor.name}</TableCell>
                      <TableCell>
                        {locationIdsToNamesMap[tractor.defaultLocationId]}
                      </TableCell>
                      <TableCell>{tractor.totalBoomWidthFeet}</TableCell>
                      <TableCell>{tractor.maxSprayWidthFeet}</TableCell>
                      <TableCell>{tractor.boomCount}</TableCell>                   
                      <TableCell>{tractor.nozzleCount}</TableCell>
                      <TableCell>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                          <Button
                            variant="outlined"
                            startIcon={<EditIcon />}
                            onClick={() => openUpdateTractorDialog(tractor)}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="outlined"
                            startIcon={<AddIcon />}
                            onClick={() => openAddDeviceDialog(tractor)}
                          >
                            Add Device
                          </Button>
                          <Button
                            variant="outlined"
                            startIcon={<BoomIcon />}
                            component={Link}
                            to={`/admin/sprayers/booms?clientId=${tractor.clientId}&tractorId=${tractor.id}`}
                          >
                            Booms
                          </Button>
                          <Button
                            variant="outlined"
                            startIcon={<ShowerIcon />}
                            component={Link}
                            to={`/admin/sprayers/nozzles?clientId=${tractor.clientId}&tractorId=${tractor.id}`}
                          >
                            Nozzles
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                    {showTractorDevices[tractor.id] ? (
                      <TableRow>
                        <TableCell sx={{ position: 'relative' }}>
                          <div
                            style={{
                              position: 'absolute',
                              left: '35px',
                              top: '16px',
                              bottom: '16px',
                              width: '2px',
                              background: 'rgba(0,0,0,0.12)'
                            }}
                          ></div>
                        </TableCell>
                        <TableCell colSpan={9}>
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>Serial Number</TableCell>
                                  <TableCell>Camera Name</TableCell>
                                  <TableCell>Flow Meter Frequency </TableCell>
                                  <TableCell>Device Type</TableCell>
                                  <TableCell>Is Active</TableCell>
                                  <TableCell>Actions</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {tractorDevices[tractor.id] &&
                                tractorDevices[tractor.id].length > 0 ? (
                                  tractorDevices[tractor.id].map((device) => (
                                    <TableRow key={device.id}>
                                      <TableCell>
                                        {device.serialNumber}
                                      </TableCell>
                                      <TableCell>{device.cameraName}</TableCell>
                                      <TableCell>
                                        {device.flowMeterFrequency}
                                      </TableCell>
                                      <TableCell>
                                        {device.deviceType === 'Recorder'
                                          ? 'Recorder'
                                          : 'Control Unit'}
                                      </TableCell>
                                      <TableCell>
                                        {device.isActive
                                          ? 'Active'
                                          : 'Inactive'}
                                      </TableCell>
                                      <TableCell>
                                        <Button
                                          variant="outlined"
                                          onClick={() =>
                                            openUpdateDeviceDialog(
                                              tractor,
                                              device
                                            )
                                          }
                                        >
                                          <EditIcon />
                                          Edit
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  ))
                                ) : (
                                  <TableRow>
                                    <TableCell>
                                      No devices for this tractor
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </TableCell>
                      </TableRow>
                    ) : (
                      ''
                    )}
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* if-gates force to re-render with the tractor to be updated */}
        {isAddTractorDialogOpen && (
          <AddTractorDialog
            client={selectedClient}
            locations={locations}
            open={isAddTractorDialogOpen}
            onClose={closeAddTractorDialog}
            onSuccess={handleAddTractorSuccess}
          />
        )}
        {isUpdateTractorDialogOpen && (
          <UpdateTractorDialog
            tractor={tractorToUpdate}
            locations={locations}
            open={isUpdateTractorDialogOpen}
            onClose={closeUpdateTractorDialog}
            onSuccess={handleUpdateTractorSuccess}
          />
        )}
        {isAddDeviceDialogOpen && (
          <AddDeviceDialog
            open={isAddDeviceDialogOpen}
            client={selectedClient}
            tractor={tractorToUpdate}
            unaffiliatedDevices={unaffiliatedDevices}
            onClose={closeAddDeviceDialog}
            onSuccess={() => handleAddDeviceSuccess(tractorToUpdate)}
          />
        )}
        {isUpdateDeviceDialogOpen && (
          <UpdateDeviceDialog
            open={isUpdateDeviceDialogOpen}
            client={selectedClient}
            tractor={tractorToUpdate}
            device={deviceToUpdate}
            onClose={closeUpdateDeviceDialog}
            onSuccess={() => handleUpdateDeviceSuccess(tractorToUpdate)}
          />
        )}
      </div>
    </>
  );
};

export default AdminConfigTractors;
