import React from 'react';
import styles from './Text.module.scss';
import { CircularProgress } from '@mui/material';

/**
 * Text component displays text with different style variations and an optional loading state.
 *
 * @example
 * <Text tag='div' type='body' className='custom-class' style={{ fontSize: '10px' }}>Your text</Text>
 */

type TextType =
  | 'label'
  | 'label-medium'
  | 'body'
  | 'bodyInput'
  | 'caption'
  | 'caption-medium'
  | 'button'
  | 'button-medium'
  | 'stat'
  | 'start-medium'
  | 'stat-large';

interface ITextProps {
  children: React.ReactNode;
  tag?: keyof JSX.IntrinsicElements;
  disabled?: boolean;
  type?: TextType;
  className?: string;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler;
  isLoading?: boolean;
}

const classByText: Record<TextType, string> = {
  label: styles.label,
  'label-medium': styles['label-medium'],
  body: styles.body,
  bodyInput: styles.bodyInput,
  caption: styles.caption,
  'caption-medium': styles['caption-medium'],
  button: styles.button,
  'button-medium': styles['button-medium'],
  stat: styles.stat,
  'start-medium': styles['start-medium'],
  'stat-large': styles['stat-large']
};

const Text: React.FC<ITextProps> = ({
  children,
  className = '',
  type = 'label',
  style = {},
  tag: Tag = 'p',
  disabled = false,
  onClick,
  isLoading = false
}) => {
  const tagClassName = `${className} ${styles[className]} ${classByText[type]} ${disabled || isLoading ? styles.disabled : ''}`;

  return (
    <Tag
      className={tagClassName}
      style={style}
      onClick={onClick}
      disabled={disabled || isLoading}
    >
      {children}
      {isLoading ? <CircularProgress className={styles.loadingIcon} /> : null}
    </Tag>
  );
};

export default Text;
