import React, { useState } from 'react';
import RadarWidget from '../../widgets/RadarWidget';
import { ChartDataset, ChartOptions } from 'chart.js';
import { getDefaultDateFilterRange } from '../../../utils/dateFormat';
import useSavingsPerBlock from '../../../hooks/useSavingsPerBlock';
import { EReportName } from '../../../constants/reports';
import SavingsPerBlockFilterPanel from '../../FilterPanel/SavingsPerBlockFilterPanel/SavingsPerBlockFilterPanel';
import { ISavingsPerBlockProperties } from '../../FilterPanel/SavingsPerBlockFilterPanel/interface';

interface IAdditionalInfo {
  subLocationName: string;
  totalSprayedCount: number;
  totalPesticideSavingAmountGallons: number;
  persentageSavedFromPreviousRun: number;
}

interface CustomRadarDataset extends ChartDataset<'radar', (number | null)[]> {
  additionalInfo?: IAdditionalInfo[]; // Custom property
}

const SavingsPerBlock = () => {
  const [properties, setProperties] = useState<ISavingsPerBlockProperties>({
    date: getDefaultDateFilterRange(),
    block: []
  });

  const { data, isLoading, error } = useSavingsPerBlock(properties);

  const subLocationNames: string[] | undefined = data?.map(
    (el) => el.subLocationName
  );

  const totalPesticideSavingAmountGallons: number[] | undefined = data?.map(
    (el) => el.totalPesticideSprayedGallons
  );

  const radarWidgetData = {
    labels: subLocationNames,
    datasets: [
      {
        label: 'Gallons Saved',
        data: totalPesticideSavingAmountGallons,
        additionalInfo: data
      }
    ] as CustomRadarDataset[]
  };

  const options: ChartOptions<'radar'> = {
    plugins: {
      tooltip: {
        callbacks: {
          title: (title) => {
            return `🌱 ${title[0].label}`;
          },
          label: (tooltipItem) => {
            const label = tooltipItem.label;
            const dataset = tooltipItem.dataset as CustomRadarDataset;
            const additionalInfo = dataset.additionalInfo?.find(
              (el: IAdditionalInfo) => el.subLocationName === label
            );

            return [
              `saved gallons: ${additionalInfo?.totalPesticideSavingAmountGallons}`
            ];
          }
        }
      }
    }
  };

  return (
    <RadarWidget
      error={error}
      title={EReportName.SpraysPerBlock}
      icon="📊"
      description="Shows the amount of sprays per block."
      isLoading={isLoading}
      properties={properties}
      setProperties={setProperties}
      isExport
      filter={
        <SavingsPerBlockFilterPanel
          properties={properties}
          setProperties={setProperties}
        />
      }
      data={radarWidgetData}
      options={options}
    />
  );
};

export default SavingsPerBlock;
