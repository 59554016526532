import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import authService from '../../../api-authorization/AuthorizeService';

interface IBoom {
  id: number;
  name: string;
}
interface IAddNozzleDialogProps {
  clientId: string;
  tractorId: string;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

type FormValues = {
  numberToAdd: number;
  nozzleBrand: string;
  nozzleAngle: number;
  nozzleGpm: number;
  sprayWidth: number;
  overlap: number;
  tractorBoomId: number;
};

const AddNozzleDialog: React.FC<IAddNozzleDialogProps> = ({
  clientId,
  tractorId,
  open,
  onClose,
  onSuccess
}) => {
  const [loading, setLoading] = useState(false);
  const [booms, setBooms] = useState<IBoom[]>([]);
  const { control, handleSubmit, register, watch } = useForm<FormValues>();
  const numberToAdd = watch('numberToAdd');

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = parseInt(event.target.value, 10);
    if (value < 0) {
      event.target.value = '0';
    }
    if (event.target.name === 'numberToAdd' && value < 1) {
      event.target.value = '1';
    }
    if (event.target.name === 'overlap' && value > 100) {
      event.target.value = '100';
    }
  };

  const addNozzles = async (data: FormValues) => {
    setLoading(true);
    const token = await authService.getAccessToken();
    const n = data.numberToAdd;
    const body = Array.from({ length: n }, () => ({
      nozzleBrand: data.nozzleBrand,
      nozzleAngle: data.nozzleAngle,
      nozzleGpm: data.nozzleGpm,
      sprayWidth: data.sprayWidth,
      overlap: data.overlap / 100,
      tractorBoomId: data.tractorBoomId,
      clientId: clientId,
      tractorId: tractorId
    }));
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/admin/tractors/nozzles`,
      {
        method: 'POST',
        headers: !token
          ? {}
          : {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
            },
        body: JSON.stringify(body)
      }
    );
    if (response.ok) {
      toast.success('Nozzles added successfully!');
      onSuccess();
    } else {
      toast.error(`${response.status} ${response.statusText}`);
    }
    setLoading(false);
  };

  const fetchTractorBooms = async () => {
    const token = await authService.getAccessToken();

    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/admin/tractors/booms?clientId=${clientId}&tractorId=${tractorId}`,
      {
        headers: !token ? {} : { Authorization: `Bearer ${token}` }
      }
    );

    const booms: IBoom[] = await response.json();
    setBooms(booms);
  };

  useEffect(() => {
    fetchTractorBooms();
  }, []);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <AddIcon />
        Add Nozzle(s)
      </DialogTitle>
      <form onSubmit={handleSubmit(addNozzles)}>
        <DialogContent sx={{ maxWidth: '480px', paddingTop: 0 }}>
          <Typography gutterBottom>
            Add nozzles from left to right when looking at the boom from behind
            the sprayer.
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <FormControl>
              <FormLabel>Number To Add</FormLabel>
              <Controller
                control={control}
                name="numberToAdd"
                defaultValue={1}
                render={({ field }) => (
                  <TextField
                    type="number"
                    autoFocus
                    disabled={loading}
                    {...field}
                    onChange={(e) => {
                      handleChange(e);
                      field.onChange(e);
                    }}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Boom</FormLabel>
              <Select {...register('tractorBoomId')}>
                {booms.map((boom) => (
                  <MenuItem key={boom.id} value={boom.id}>
                    {boom.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Nozzle Brand</FormLabel>
              <Select defaultValue="TeeJet" {...register('nozzleBrand')}>
                <MenuItem value="TeeJet">TeeJet</MenuItem>
                <MenuItem value="Wilger">Wilger</MenuItem>
                <MenuItem value="MagnoJet">MagnoJet</MenuItem>
              </Select>
            </FormControl>
            <Stack direction="row" spacing={2}>
              <FormControl>
                <FormLabel>Nozzle Angle</FormLabel>
                <Controller
                  control={control}
                  name="nozzleAngle"
                  defaultValue={0}
                  render={({ field }) => (
                    <TextField
                      type="number"
                      disabled={loading}
                      InputProps={{ inputProps: { step: 'any' } }}
                      {...field}
                      onChange={(e) => {
                        handleChange(e);
                        field.onChange(e);
                      }}
                    />
                  )}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Nozzle GPM</FormLabel>
                <Controller
                  control={control}
                  name="nozzleGpm"
                  defaultValue={0}
                  render={({ field }) => (
                    <TextField
                      type="number"
                      disabled={loading}
                      InputProps={{ inputProps: { step: 'any' } }}
                      {...field}
                      onChange={(e) => {
                        handleChange(e);
                        field.onChange(e);
                      }}
                    />
                  )}
                />
              </FormControl>
            </Stack>
            <Stack direction="row" spacing={2}>
              <FormControl>
                <FormLabel>Spray Width (in)</FormLabel>
                <Controller
                  control={control}
                  name="sprayWidth"
                  defaultValue={0}
                  render={({ field }) => (
                    <TextField
                      type="number"
                      disabled={loading}
                      {...field}
                      onChange={(e) => {
                        handleChange(e);
                        field.onChange(e);
                      }}
                    />
                  )}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Overlap (%)</FormLabel>
                <Controller
                  control={control}
                  name="overlap"
                  defaultValue={0}
                  render={({ field }) => (
                    <TextField
                      type="number"
                      disabled={loading}
                      {...field}
                      onChange={(e) => {
                        handleChange(e);
                        field.onChange(e);
                      }}
                    />
                  )}
                />
              </FormControl>
            </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" disabled={loading} onClick={onClose}>
            Cancel
          </Button>
          <Button variant="outlined" disabled={loading} type="submit">
            Add Nozzle{numberToAdd > 1 ? 's' : ''}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddNozzleDialog;
