import React from 'react';
import styles from '../FilterPanel.module.scss';
import FilterOption from '../Options/FilterOption';
import DateFilterOption from '../Options/DateFilterOption';
import { ISavingsProperties } from './interface';
import useSWR from 'swr';
import { LOCATIONS_API_ENDPOINTS } from '../../../constants/api';
import { fetchBlocks } from '../../../utils/api-utils';
import { DateRange } from 'react-day-picker';

/**
 * Filter component needs to filter and export data.
 *
 * @example
 * <Filter
 *    open={isOpen}
 *    properties={properties}
 *    setProperties={setProperties}
 *  />
 */

interface IFilterProps {
  open: boolean;
  properties: ISavingsProperties;
  setProperties: React.Dispatch<React.SetStateAction<ISavingsProperties>>;
}

const SavingsFilter: React.FC<IFilterProps> = ({
  open,
  properties,
  setProperties
}) => {
  const { data, isLoading } = useSWR(`${LOCATIONS_API_ENDPOINTS.blocks}`, () =>
    fetchBlocks()
  );

  const handleDateFilterChange = (value: DateRange | undefined) => {
    setProperties({ ...properties, date: value });
  };

  const handleBlockChange = (value: number | number[] | undefined) => {
    if (value) {
      const selectedBlock = data?.find(
        (blockItem) => blockItem.id === Number(value)
      );
      setProperties({ ...properties, block: selectedBlock });
    } else {
      setProperties({ ...properties, block: undefined });
    }
  };

  return (
    <div className={open ? styles.filter : styles.filterClosed}>
      <DateFilterOption
        value={properties.date}
        onChange={handleDateFilterChange}
      />
      <FilterOption
        iconPath="/images/plant.svg"
        title="Block"
        options={data || []}
        isLoading={isLoading}
        value={properties?.block?.id}
        onChange={handleBlockChange}
      />
    </div>
  );
};

export default SavingsFilter;
