import React from 'react';
import styles from '../FilterPanel.module.scss';
import DateFilterOption from '../Options/DateFilterOption';
import { IProperties } from './interface';
import { DateRange } from 'react-day-picker';
import clsx from 'clsx';

/**
 * RunsFilter component needs to filter and export run summary data.
 *
 * @example
 * <RunsFilter
 *    open={isOpen}
 *    properties={properties}
 *    setProperties={setProperties}
 *  />
 */

interface IFilterProps {
  open: boolean;
  properties: IProperties;
  setProperties: React.Dispatch<React.SetStateAction<IProperties>>;
}

const RunSummaryFilter: React.FC<IFilterProps> = ({
  open,
  properties,
  setProperties
}) => {
  const handleDatePickerChange = (value: DateRange | undefined) => {
    setProperties({ ...properties, date: value });
  };

  return (
    <div
      className={
        open ? clsx(styles.filter, styles.runsFilter) : styles.filterClosed
      }
    >
      <DateFilterOption
        value={properties.date}
        onChange={handleDatePickerChange}
      />
    </div>
  );
};

export default RunSummaryFilter;
