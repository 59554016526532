import React from 'react';
import WidgetCard, { IWidgetCardProps } from '../../WidgetCard/WidgetCard';
import Text from '../../typographies/Text/Text';

import styles from './TotalPercentageWithAmountWidget.module.scss';
import Legend from '../../Legend';

interface Props extends Omit<IWidgetCardProps, 'children'> {
  data: {
    label: string;
    percentage: number;
    amount: number;
  };
}

const TotalPercentageWithAmountWidget: React.FC<Props> = ({
  data,
  ...widgetProps
}) => {
  return (
    <WidgetCard isEmpty={!data.percentage && !data.amount} {...widgetProps}>
      <div className={styles.container}>
        <Legend title={data.label} />
        <Text type="stat-large">{data.percentage}%</Text>
        <div className={styles.amountContainer}>
          <Text className={styles.amountText} type="stat">
            {data.amount}
          </Text>
        </div>
      </div>
    </WidgetCard>
  );
};

export default TotalPercentageWithAmountWidget;
