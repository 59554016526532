import React, { useState } from 'react';
import { ClickAwayListener } from '@mui/material';
import Text from '../../typographies/Text/Text';
import SavingsPerBlockFilter from './SavingsPerBlockFilter';
import { appliedFilters } from '../../../utils/filter-utils';
import { ISavingsPerBlockProperties } from './interface';

/**
 * RunsFilterPanel component needs to filter and export run summary data.
 *
 * @example
 * <RunsFilterPanel properties={properties} setProperties={setPproperties}/>
 */

interface ISavingsPerBlockFilterPanelProps {
  properties: ISavingsPerBlockProperties;
  setProperties: React.Dispatch<
    React.SetStateAction<ISavingsPerBlockProperties>
  >;
}

const SavingsPerBlockFilterPanel: React.FC<
  ISavingsPerBlockFilterPanelProps
> = ({ properties, setProperties }) => {
  const [filterOpen, setFilterOpen] = useState(false);

  const onFilterToggle = () => {
    setFilterOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setFilterOpen(false);
  };

  const filters = appliedFilters(properties);
  const appliedFiltersCount = filters.length;

  return (
    <ClickAwayListener onClickAway={handleClickAway} mouseEvent="onMouseDown">
      <div>
        <Text tag="button" onClick={onFilterToggle} className="filter">
          <img src="/images/filter_alt.svg" />
          Filter
          <span>{appliedFiltersCount > 0 && `• ${appliedFiltersCount}`}</span>
        </Text>
        {filterOpen ? (
          <SavingsPerBlockFilter
            open={filterOpen}
            properties={properties}
            setProperties={setProperties}
          />
        ) : null}
      </div>
    </ClickAwayListener>
  );
};

export default SavingsPerBlockFilterPanel;
