import React from 'react';
import styles from './ExportButton.module.scss';
import { TReportType } from '../../interfaces/report-type';
import Text from '../typographies/Text/Text';

/**
 * ExportOptions component needs to export widget in PDF or XLSX format.
 *
 * @example
 * <ExportOptions
 *   handleChangeExportOption={handleChangeExportOption}
 * />
 */

interface IExportOptionsProps {
  handleChangeExportOption: (param: TReportType) => void;
}

const ExportOptions: React.FC<IExportOptionsProps> = ({
  handleChangeExportOption
}) => {
  return (
    <div className={styles.exportOptions}>
      <Text tag="button" onClick={() => handleChangeExportOption('pdf')}>
        Export PDF
      </Text>
      <Text tag="button" onClick={() => handleChangeExportOption('xlsx')}>
        Export XLS
      </Text>
    </div>
  );
};

export default ExportOptions;
