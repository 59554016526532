import React, { useState } from 'react';
import { ClickAwayListener } from '@mui/material';
import styles from '../FilterPanel.module.scss';
import Text from '../../typographies/Text/Text';
import SavingsFilter from './SavingsFilter';
import FiltrationChip from '../FiltrationChip';
import { ISavingsProperties } from './interface';
import ExportButton from '../../ExportButton';
import { WIDGETS } from '../../../constants/savings';
import { EReportName } from '../../../constants/reports';
import { appliedFilters } from '../../../utils/filter-utils';
import { Filters } from '../../../interfaces/filtration-type';

/**
 * FilterPanel component needs to filter and export data.
 *
 * @example
 * <FilterPanel
 *  properties={properties}
 *  setProperties={setPproperties} />
 */

interface IDateFilterOptionProps {
  properties: ISavingsProperties;
  setProperties: React.Dispatch<React.SetStateAction<ISavingsProperties>>;
}

const SavingsFilterPanel: React.FC<IDateFilterOptionProps> = ({
  properties,
  setProperties
}) => {
  const [filterOpen, setFilterOpen] = useState(false);

  const onFilterToggle = () => {
    setFilterOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setFilterOpen(false);
  };

  const clearProperty = (propertyName: string) => {
    setProperties((prev) => {
      return {
        ...prev,
        [propertyName]: ''
      };
    });
  };

  const filters: Filters = appliedFilters(properties);
  const appliedFiltersCount = filters.length;

  return (
    <ClickAwayListener onClickAway={handleClickAway} mouseEvent="onMouseDown">
      <div className={styles.filterPanelWrapper}>
        <div className={styles.filterPanel}>
          <Text tag="button" onClick={onFilterToggle} className="filter">
            <img src="/images/filter_alt.svg" />
            Filter
            <span>{appliedFiltersCount > 0 && `• ${appliedFiltersCount}`}</span>
          </Text>
          <div className={styles.slash}></div>
          <ExportButton
            widgets={WIDGETS}
            name={EReportName.Savings}
            properties={properties}
          />
          {filterOpen ? (
            <SavingsFilter
              open={filterOpen}
              properties={properties}
              setProperties={setProperties}
            />
          ) : null}
        </div>
        {appliedFiltersCount ? (
          <div className={styles.filtrationChipWrapper}>
            {filters.map(([key, value]) => (
              <FiltrationChip
                key={key}
                propertyName={key}
                filtration={value}
                onDelete={
                  !['date'].includes(key) ? () => clearProperty(key) : undefined
                }
              />
            ))}
          </div>
        ) : null}
      </div>
    </ClickAwayListener>
  );
};

export default SavingsFilterPanel;
