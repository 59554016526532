import { sub, format, set, parse } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { DAYS_TO_EXCLUDE } from '../constants/numbers';
import { CHART_LABELS_DATE_FORMAT } from '../constants/charts';
import { dateFormatWithTimeZone } from '../constants/dateFormat';

const locales: { [key: string]: Locale } = { enUS };

declare global {
  interface Window {
    __localeId__: string;
  }
}

/**
 * Formats a given date to UTC and returns it in a specific format based on the locale.
 *
 * @param {Date | undefined} date - The date to be formatted. If the date is undefined, an empty string is returned.
 * @param {string} [formatStr='PP'] - The format string to specify how the date should be formatted (defaults to 'PP').
 * @returns {string} - A formatted string representing the date in UTC.
 *
 * @example
 * const formattedDate = UTCFormat(new Date(), 'dd MMM yyyy');
 */

export const UTCFormat = (
  date: Date | undefined,
  formatStr: string = 'PP'
): string => {
  if (!date) return '';
  const newDate = set(date, {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0
  });
  return format(newDate, formatStr, {
    locale: locales[window.__localeId__]
  });
};

export const getDefaultDateFilterRange = () => {
  const dateTo = new Date();
  return { from: sub(dateTo, { days: DAYS_TO_EXCLUDE }), to: dateTo };
};

export const formatChartLabelDate = <T extends { date: string }>(item: T) => {
  const parsedDate = parse(item.date, dateFormatWithTimeZone, new Date());
  return format(parsedDate, CHART_LABELS_DATE_FORMAT);
};
