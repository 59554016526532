import React, { useRef, useState } from 'react';
import 'react-day-picker/dist/style.css';
import { DateRange } from 'react-day-picker';
import styles from '../FilterPanel.module.scss';
import Text from '../../typographies/Text/Text';
import { Box, ClickAwayListener, OutlinedInput } from '@mui/material';
import { dateFormatMonthDayYear } from '../../../constants/dateFormat';
import DatePickerComponent from '../../DatePicker/DatePicker';
import { format } from '../../../../utils';

interface DateFilterProps {
  value: DateRange | undefined;
  onChange: (value: DateRange | undefined) => void;
}

const DateFilterOption: React.FC<DateFilterProps> = ({ value, onChange }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const [open, setOpen] = useState(false);
  const [formattedDateFrom, setFormattedDateFrom] = useState<
    Date | string | undefined
  >(format(value?.from, dateFormatMonthDayYear));
  const [formattedDateTo, setFormattedDateTo] = useState<
    Date | string | undefined
  >(format(value?.to, dateFormatMonthDayYear));

  const formattedSelectedDateFrom = format(value?.from, dateFormatMonthDayYear);
  const formattedSelectedDateTo = format(value?.to, dateFormatMonthDayYear);

  const handleApply = (selected: DateRange | undefined) => {
    onChange(selected);
  };

  const handleDateFromChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormattedDateFrom(event.target.value);
  };
  const handleDateToChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormattedDateTo(event.target.value);
  };
  const handleDateFromKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.key === 'Enter') {
      const inputValue = event.currentTarget.value;
      const parsedDate = new Date(inputValue);
      if (!isNaN(parsedDate.getTime())) {
        handleApply({
          from: parsedDate,
          to: value?.to
        });
      }
    }
  };
  const handleDateToKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.key === 'Enter') {
      const inputValue = event.currentTarget.value;
      const parsedDate = new Date(inputValue);
      if (!isNaN(parsedDate.getTime())) {
        handleApply({
          from: value?.from,
          to: parsedDate
        });
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <ClickAwayListener onClickAway={handleClose} mouseEvent="onMouseDown">
      <div className={styles.filterOption}>
        <img src="/images/calendar_month.svg" alt="Calendar month" />
        <div>
          <Text type="label" className={styles.title}>
            Date
          </Text>
          <Box>
            <OutlinedInput
              ref={inputRef}
              className={styles.dateInput}
              value={
                value
                  ? `${formattedSelectedDateFrom} - ${formattedSelectedDateTo}`
                  : 'Select property...'
              }
              onClick={handleClick}
              readOnly
              fullWidth
            />
            <div
              className={open ? styles.dateRangeFilter : styles.filterClosed}
            >
              <div className={styles.dateInputs}>
                <div>
                  <Text type="label">Start</Text>
                  <OutlinedInput
                    ref={inputRef}
                    value={formattedDateFrom}
                    onKeyDown={handleDateFromKeyDown}
                    onChange={handleDateFromChange}
                    fullWidth
                    sx={{
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none' // Remove the border
                      }
                    }}
                  />
                </div>
                <div>
                  <Text type="label">End</Text>
                  <OutlinedInput
                    ref={inputRef}
                    value={formattedDateTo}
                    onKeyDown={handleDateToKeyDown}
                    onChange={handleDateToChange}
                    fullWidth
                    sx={{
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none' // Remove the border
                      }
                    }}
                  />
                </div>
              </div>
              <DatePickerComponent
                onChange={(selected: DateRange | undefined) => {
                  handleApply(selected);
                  handleClose();
                }}
                handleClose={handleClose}
                value={value}
              />
            </div>
          </Box>
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default DateFilterOption;
