import React from 'react';
import WidgetGroup from '../../WidgetGroup';
import styles from './AssetManagement.module.scss';
import PerfomanceBreakdownInfo from './PerfomanceBreakdownInfo';
import SprayerInfo from './SprayerInfo';

const AssetManagement: React.FC = () => {
  return (
    <WidgetGroup title="⚙️ Asset Management">
      <div className={styles.container}>
        <PerfomanceBreakdownInfo />
        <SprayerInfo />
      </div>
    </WidgetGroup>
  );
};

export default AssetManagement;
