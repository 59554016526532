export const WIDGETS = {
  SAVINGS: {
    id: 'SAVINGS',
    title: '📊 Herbicide Savings',
    description: 'Displays the amount of herbicide saved in gallons.'
  },
  SPRAYED: {
    id: 'SPRAYED',
    title: '📊 Sprayed area',
    description: 'Shows the total area sprayed in acres.'
  },
  USAGE: {
    id: 'USAGE',
    title: '📊 Herbicide Usage',
    subtitle: 'gal',
    description: 'Displays the herbicide usage dynamics.'
  },
  SAVINGS_DYNAMICS: {
    id: 'SAVINGS_DYNAMICS',
    title: '📊 Herbicide Savings Dynamics',
    subtitle: 'gal',
    description: 'Shows the herbicide savings trend.'
  }
};
