import _ from 'lodash';
import React, { useState, useEffect, useCallback } from 'react';
import toast from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import {
  Box,
  Button,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import authService from '../../api-authorization/AuthorizeService';
import styles from './AdminConfigTractorNozzles.module.css';
import AddNozzleDialog from './AddNozzleDialog/AddNozzleDialog';
import DeleteNozzleDialog from './DeleteNozzleDialog/DeleteNozzleDialog';

const AdminConfigTractorNozzles = () => {
  const [searchParams] = useSearchParams();
  const [tractor, setTractor] = useState({});
  const [nozzles, setNozzles] = useState([]);
  const [booms, setBooms] = useState([]);
  const [fetchedNozzles, setFetchedNozzles] = useState([]);
  const [nozzleForDeletion, setNozzleForDeletion] = useState(null);
  const [loading, setLoading] = useState(true);
  const [, setError] = useState(false);
  const [addNozzleDialogOpen, setIsAddNozzleDialogOpen] = useState(false);
  const [deleteNozzleDialogOpen, setIsDeleteNozzleDialogOpen] = useState(false);


  const getClientId = useCallback(
    () => searchParams.get('clientId'),
    [searchParams]
  );

  const getTractorId = useCallback(
    () => searchParams.get('tractorId'),
    [searchParams]
  );

  const fetchTractor = useCallback(async () => {
    setLoading(true);
    try {
      const clientId = getClientId();
      const tractorId = getTractorId();
      const token = await authService.getAccessToken();
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/admin/tractor?clientId=${clientId}&tractorId=${tractorId}`,
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` }
        }
      );
      const tractor = await response.json();
      setTractor(tractor);
      setLoading(false);
      setError(false);
    } catch (error) {
      setTimeout(() => {
        setError(true);
        setLoading(false);
      }, 1000);
    }
  }, [getClientId, getTractorId]);

  const fetchTractorBooms = useCallback(async () => {
    setLoading(true);
    try {
      const clientId = getClientId();
      const tractorId = getTractorId();
      const token = await authService.getAccessToken();
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/admin/tractors/booms?clientId=${clientId}&tractorId=${tractorId}`,
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` }
        }
      );
      const booms = await response.json();
      setBooms(booms);
      setLoading(false);
      setError(false);
    } catch (error) {
      setTimeout(() => {
        setError(true);
        setLoading(false);
      }, 1000);
    }
  }, [getClientId, getTractorId]);

  const fetchNozzles = useCallback(async () => {
    setLoading(true);
    try {
      const clientId = getClientId();
      const tractorId = getTractorId();
      const token = await authService.getAccessToken();
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/admin/tractors/nozzles?clientId=${clientId}&tractorId=${tractorId}`,
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` }
        }
      );
      const nozzles = (await response.json()).map((n) => ({
        ...n,
        overlap: Math.round(n.overlap * 100)
      }));
      setFetchedNozzles(nozzles);
      setNozzles(nozzles);
      setLoading(false);
      setError(false);
    } catch (error) {
      setTimeout(() => {
        setError(true);
        setLoading(false);
      }, 1000);
    }
  }, [getClientId, getTractorId]);

  useEffect(() => {
    fetchTractor();
    fetchNozzles();
    fetchTractorBooms();
  }, [getClientId, getTractorId, fetchTractor, fetchNozzles]);

  const onEditNozzle = (nozzleId, fieldName, fieldType, newValue) => {
    if (fieldType !== 'select') {
      newValue = Math.max(0, newValue);
      if (fieldType === 'decimal') {
        newValue = parseFloat(newValue);
      } else if (fieldType === 'integer') {
        newValue = parseInt(newValue, 10);
      } else if (fieldType === 'percent') {
        newValue = parseInt(newValue, 10);
        newValue = Math.min(100, newValue);
      }
    }
    const updatedNozzles = nozzles.map((nozzle) => {
      let value;
      if (nozzle.id === nozzleId) {
        return {
          ...nozzle,
          [fieldName]: newValue
        };
      }
      return nozzle;
    });
    setNozzles(updatedNozzles);
  };

  const hasNozzleValueChanged = (nozzle, fieldName) => {
    const fetchedNozzle = fetchedNozzles.find(
      (fetchedNozzle) => fetchedNozzle.id === nozzle.id
    );
    return fetchedNozzle[fieldName] !== nozzle[fieldName];
  };

  const haveAnyValuesChanged = () => {
    return _.isEqual(fetchedNozzles, nozzles);
  };

  const discardChanges = () => {
    setNozzles(fetchedNozzles);
  };

  const updateNozzles = async () => {
    const token = await authService.getAccessToken();

    const body = nozzles.map((n) => ({ ...n, overlap: n.overlap / 100 }));
    await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/admin/tractors/nozzles`,
      {
        method: 'POST',
        headers: !token
          ? {}
          : {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
            },
        body: JSON.stringify(body)
      }
    );
    toast.success('Nozzles updated successfully!');
    fetchNozzles();
  };

  const openAddNozzleDialog = () => {
    setIsAddNozzleDialogOpen(true);
  };

  const closeShowAddNozzleDialog = () => {
    setIsAddNozzleDialogOpen(false);
  };

  const handleAddNozzlesSuccess = () => {
    setIsAddNozzleDialogOpen(false);
    fetchNozzles();
  };

  const openDeleteNozzleDialog = (nozzle) => {
    setNozzleForDeletion(nozzle);
    setIsDeleteNozzleDialogOpen(true);
  };

  const closeDeleteNozzleDialog = () => {
    setNozzleForDeletion(null);
    setIsDeleteNozzleDialogOpen(false);
  };

  const handleDeleteNozzleSuccess = () => {
    closeDeleteNozzleDialog();
    fetchNozzles();
  };

  return (
    <>
      <div className={styles.container}>
        <Typography variant="h4" sx={{ pb: 1 }}>
          {tractor.name}
        </Typography>
        <Typography variant="h5" sx={{ pb: 1 }}>
          Nozzle Management
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={2}
          alignItems="center"
        >
          <Box>
            <Typography>
              Add nozzles from left to right when looking at the boom from
              behind the sprayer.
            </Typography>
          </Box>
          {haveAnyValuesChanged() ? (
            <Button
              variant="outlined"
              disabled={loading}
              startIcon={<AddIcon />}
              onClick={openAddNozzleDialog}
            >
              Add Nozzles
            </Button>
          ) : (
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                variant="outlined"
                startIcon={<UndoIcon />}
                onClick={discardChanges}
              >
                Discard Changes
              </Button>
              <Button
                variant="outlined"
                onClick={updateNozzles}
                startIcon={<SaveIcon />}
              >
                Save Changes
              </Button>
            </Box>
          )}
        </Stack>
        <Stack>
          <TableContainer component={Paper} sx={{ my: 1 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Nozzle Brand</TableCell>
                  <TableCell>Nozzle Angle</TableCell>
                  <TableCell>Nozzle GPM</TableCell>
                  <TableCell>Spray Width (in)</TableCell>
                  <TableCell>Overlap (%)</TableCell>
                  <TableCell>Boom</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {nozzles.map((nozzle, index) => (
                  <TableRow key={nozzle.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      <Select
                        value={nozzle.nozzleBrand}
                        error={hasNozzleValueChanged(nozzle, 'nozzleBrand')}
                        onChange={(e) =>
                          onEditNozzle(
                            nozzle.id,
                            'nozzleBrand',
                            'select',
                            e.target.value
                          )
                        }
                      >
                        <MenuItem value="TeeJet">TeeJet</MenuItem>
                        <MenuItem value="Wilger">Wilger</MenuItem>
                        <MenuItem value="MagnoJet">MagnoJet</MenuItem>
                      </Select>
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        InputProps={{ inputProps: { step: 'any' } }}
                        value={nozzle.nozzleAngle}
                        error={hasNozzleValueChanged(nozzle, 'nozzleAngle')}
                        onChange={(e) =>
                          onEditNozzle(
                            nozzle.id,
                            'nozzleAngle',
                            'integer',
                            e.target.value
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        InputProps={{ inputProps: { step: 'any' } }}
                        value={nozzle.nozzleGpm}
                        error={hasNozzleValueChanged(nozzle, 'nozzleGpm')}
                        onChange={(e) =>
                          onEditNozzle(
                            nozzle.id,
                            'nozzleGpm',
                            'decimal',
                            e.target.value
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        value={nozzle.sprayWidth}
                        error={hasNozzleValueChanged(nozzle, 'sprayWidth')}
                        onChange={(e) =>
                          onEditNozzle(
                            nozzle.id,
                            'sprayWidth',
                            'integer',
                            e.target.value
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        value={nozzle.overlap}
                        error={hasNozzleValueChanged(nozzle, 'overlap')}
                        onChange={(e) =>
                          onEditNozzle(
                            nozzle.id,
                            'overlap',
                            'percent',
                            e.target.value
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Select
                        value={nozzle.tractorBoomId}
                        error={hasNozzleValueChanged(nozzle, 'tractorBoomId')}
                        onChange={(e) =>
                          onEditNozzle(
                            nozzle.id,
                            'tractorBoomId',
                            'select',
                            e.target.value
                          )}
                      >
                        {booms.map((boom) => (
                          <MenuItem key={boom.id} value={boom.id}>
                            {boom.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        startIcon={<DeleteIcon />}
                        disabled={!haveAnyValuesChanged()}
                        onClick={() => openDeleteNozzleDialog(nozzle)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {addNozzleDialogOpen && (
            <AddNozzleDialog
              clientId={getClientId()}
              tractorId={getTractorId()}
              open={addNozzleDialogOpen}
              onClose={closeShowAddNozzleDialog}
              onSuccess={handleAddNozzlesSuccess}
            />
          )}
          {deleteNozzleDialogOpen && (
            <DeleteNozzleDialog
              clientId={getClientId()}
              nozzleId={nozzleForDeletion.id}
              open={deleteNozzleDialogOpen}
              onClose={closeDeleteNozzleDialog}
              onSuccess={handleDeleteNozzleSuccess}
            />
          )}
        </Stack>
      </div>
    </>
  );
};

export default AdminConfigTractorNozzles;
