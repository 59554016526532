import React from 'react';
import styles from './StatCard.module.css';
import { Checkbox, FormControl, ListItemText, MenuItem, OutlinedInput, Select } from '@mui/material';

export default function StatCardSelect({
    options,
    selectedOptions,
    handleChange,
    title
}) {
    const handleSelectChange = (event) => {
        handleChange(event.target.value);
    };

    return (
        <div className={`${styles.cardSelect} mt-8`}>
            <FormControl fullWidth sx={{ rowGap: '8px' }}>
            <h1>{title}</h1>

                <Select
                    multiple
                    displayEmpty
                    value={selectedOptions?.map(option => option.id) || []}
                    onChange={handleSelectChange}
                    input={<OutlinedInput />}
                    renderValue={(selectedIds) => {
                        return selectedIds
                            ?.map(id => {
                                const selectedOption = options.find(option => option.id === id);
                                return selectedOption ? selectedOption.name : '';
                            })
                            ?.join(', ');
                    }}
                    className={styles.select}
                >
                    <MenuItem disabled value="">
                        <em>{title}</em>
                    </MenuItem>
                    {options?.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            <Checkbox checked={selectedOptions?.some(item => item.id === option.id)} />
                            <ListItemText primary={option.name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}
