import { ISavingsProperties } from '../components/FilterPanel/SavingsFilterPanel/interface';
import { ISavingsPerBlockProperties } from '../components/FilterPanel/SavingsPerBlockFilterPanel/interface';

export const appliedFilters = (
  properties: ISavingsProperties | ISavingsPerBlockProperties
) => {
  return (
    properties &&
    Object.entries(properties).filter(([, value]) => {
      if (Array.isArray(value)) {
        return !!value.length;
      } else if (value && typeof value === 'object') {
        return !!Object.keys(value).length;
      }
      return;
    })
  );
};
