import React, { useState } from 'react';
import { ClickAwayListener } from '@mui/material';
import { TReportType } from '../../interfaces/report-type';
import { UTCFormat } from '../../utils/dateFormat';
import { dateFormatParams } from '../../constants/dateFormat';
import { WIDGETS } from '../../constants/savings';
import {
  exportToFileFromElement,
  exportToFileFromElements
} from '../../utils/file-export-utils';
import Text from '../typographies/Text/Text';
import ExportOptions from './ExportOptions';
import { fetchReports, fetchSprayerInfoReport } from '../../utils/api-utils';
import { EReportName } from '../../constants/reports';
import { ISavingsPerBlockProperties } from '../FilterPanel/SavingsPerBlockFilterPanel/interface';
import { ISavingsProperties } from '../FilterPanel/SavingsFilterPanel/interface';

/**
 * ExportButton component needs to export widget in PDF or XLSX format.
 *
 * @example
 * <ExportButton />
 */

interface IExportButtonProps {
  printRef?: React.RefObject<HTMLElement>; // used on single widget
  widgets?: typeof WIDGETS; // used on multiple widgets
  properties?: ISavingsProperties | ISavingsPerBlockProperties;
  name: string;
}

const ExportButton: React.FC<IExportButtonProps> = ({
  printRef,
  properties,
  name,
  widgets
}) => {
  const [loadingExport, setLoadingExport] = useState(false);
  const [exportOpen, setExportOpen] = useState(false);

  const formattedDateFrom = UTCFormat(properties?.date?.from, dateFormatParams);
  const formattedDateTo = UTCFormat(properties?.date?.to, dateFormatParams);

  const handleOpenOptions = () => {
    setExportOpen((prev) => !prev);
  };

  const handleCloseOptions = () => {
    setExportOpen(false);
  };

  const handleLoadingExport = (isLoading: boolean) => {
    setLoadingExport(isLoading);
  };

  const handleChangeExportOption = async (type: TReportType) => {
    handleCloseOptions();
    const fetch =
      name === EReportName.SpraysPerBlock
        ? fetchSprayerInfoReport
        : fetchReports;
    const block = properties?.block;

    if (printRef) {
      // Export for the first case using printRef
      await exportToFileFromElement(
        {
          type,
          dateFrom: formattedDateFrom,
          dateTo: formattedDateTo,
          subLocationIds: Array.isArray(block) ? block?.map((i) => i.id) : []
        },
        name,
        printRef,
        handleLoadingExport,
        fetch
      );
    } else if (widgets) {
      // Export for the second case using widgets
      const elementIds = Object.values(widgets).map((widget) => widget.id);
      await exportToFileFromElements(
        {
          type,
          dateFrom: formattedDateFrom,
          dateTo: formattedDateTo,
          subLocationId: !Array.isArray(block) ? block?.id : ''
        },
        name,
        elementIds,
        handleLoadingExport,
        fetchReports
      );
    }
  };

  return (
    <ClickAwayListener
      onClickAway={handleCloseOptions}
      mouseEvent="onMouseDown"
    >
      <div>
        <Text
          tag="button"
          onClick={handleOpenOptions}
          isLoading={loadingExport}
          className="filter"
        >
          <img src="/images/download.svg" alt="Download Icon" />
          {widgets ? 'Export all savings section' : 'Export'}
        </Text>
        {exportOpen ? (
          <ExportOptions handleChangeExportOption={handleChangeExportOption} />
        ) : null}
      </div>
    </ClickAwayListener>
  );
};

export default ExportButton;
