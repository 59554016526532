import React from 'react';
import styles from './WidgetCard.module.scss';
import Text from '../typographies/Text/Text';
import { ErrorType } from '../../interfaces/error-type';
import DangerousIcon from '@mui/icons-material/Dangerous';

/**
 * ErrorState component needs to show error state for widget when something happen with data.
 *
 * @example
 *  <ErrorState />
 */

export interface IErrorStateProps {
  error: ErrorType;
}

const ErrorState: React.FC<IErrorStateProps> = ({ error }) => {
  return (
    <div className={styles.state}>
      <div className={styles.errorContainer}>
        <DangerousIcon />
        <div>
          <Text type="label">
            Something went wrong. Please refresh the page or log back into your
            account.
          </Text>
        </div>
      </div>
      <div className={styles.infoContainer}>
        <img src="/images/filter_alt.svg" />
        <div>
          <Text type="label">Error text:</Text>
          <Text type="label">{error.message}</Text>
        </div>
      </div>
    </div>
  );
};

export default ErrorState;
