import React, { useState } from 'react';
import { DateRange } from 'react-day-picker';
import styles from './FilterPanel.module.scss';
import { format } from '../../../utils';
import { Chip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { dateFormatMonthDayYear } from '../../constants/dateFormat';
import { BlockItem } from '../../interfaces/blocks';
import { FilterValue } from '../../interfaces/filtration-type';
import Text from '../typographies/Text/Text';

/**
 * FiltrationChip component needs to display and delete selected filter in chip.
 *
 * @example
 * <FiltrationChip
 *   filtration="applied filter"
 *   onDelete={() => {}}
 * />
 */

interface IFiltrationChipProps {
  filtration: FilterValue;
  propertyName: string;
  onDelete?: () => void;
  onDeleteItem?: (propertyName: string, itemId: number) => void;
}

const isDateRangeFilter = (
  filtration: FilterValue
): filtration is DateRange => {
  return (
    (filtration as DateRange).from !== undefined &&
    (filtration as DateRange).to !== undefined
  );
};

const isBlockItem = (filtration: FilterValue): filtration is BlockItem => {
  return (
    (filtration as BlockItem)?.id !== undefined &&
    (filtration as BlockItem)?.name !== undefined
  );
};

const isBlockItemArray = (
  filtration: FilterValue
): filtration is BlockItem[] => {
  return Array.isArray(filtration) && filtration.every(isBlockItem);
};

const isFilterProperty = (
  filtration: FilterValue
): filtration is BlockItem | BlockItem[] => {
  return isBlockItem(filtration) || isBlockItemArray(filtration);
};

const FiltrationChip: React.FC<IFiltrationChipProps> = ({
  filtration,
  propertyName,
  onDelete,
  onDeleteItem
}) => {
  const [showAll, setShowAll] = useState(false);

  const itemsToDisplay = Array.isArray(filtration)
    ? filtration.slice(0, showAll ? filtration.length : 3)
    : [];

  const isDateRange = isDateRangeFilter(filtration);
  const isFilter = isFilterProperty(filtration);

  const formattedSelectedDateFrom = isDateRange
    ? format(filtration.from, dateFormatMonthDayYear)
    : undefined;

  const formattedSelectedDateTo = isDateRange
    ? format(filtration.to, dateFormatMonthDayYear)
    : undefined;

  const capitalizedPropertyName = propertyName.replace(/^\w/, (c) =>
    c.toUpperCase()
  );

  const handleShowMoreLess = () => {
    setShowAll(!showAll);
  };

  const renderChipItems = () => {
    if (isFilter) {
      if (Array.isArray(filtration)) {
        return (
          <div>
            {itemsToDisplay.map((item, index) => (
              <Chip
                key={index}
                className={styles.chip}
                onDelete={() => onDeleteItem?.(propertyName, item.id)}
                deleteIcon={<CloseIcon />}
                label={`${capitalizedPropertyName} - ${item.name}`}
              />
            ))}
          </div>
        );
      } else {
        return (
          <Chip
            className={styles.chip}
            label={filtration.name.toString()}
            onDelete={onDelete}
            deleteIcon={<CloseIcon />}
          />
        );
      }
    } else if (isDateRange) {
      return (
        <Chip
          className={`${styles.chip} ${styles.defaultChip}`}
          label={`${formattedSelectedDateFrom} - ${formattedSelectedDateTo}`}
          onDelete={onDelete}
          deleteIcon={<CloseIcon />}
        />
      );
    } else {
      return (
        <Chip
          className={styles.chip}
          label={filtration?.toString()}
          onDelete={onDelete}
          deleteIcon={<CloseIcon />}
        />
      );
    }
  };

  return (
    <div className={styles.filtrationChipContainer}>
      <Text tag="label">{capitalizedPropertyName}:</Text>
      {renderChipItems()}
      {Array.isArray(filtration) && filtration.length > 3 && (
        <Text tag="button" onClick={handleShowMoreLess}>
          {showAll ? 'Show Less' : 'Show More'}
        </Text>
      )}
    </div>
  );
};

export default FiltrationChip;
