import React from 'react';
import { Divider } from '@mui/material';

import Navbar from '../../../components-container/components/Navbar';
import Breadcrumbs from '../../../command-center/components/Breadcrumbs/Breadcrumbs';
import PageTitle from '../../../command-center/components/PageTitle/PageTitle';
import Savings from '../../../command-center/components/modules/Savings';
import Runs from '../../../command-center/components/modules/Runs';
import AssetManagement from '../../../command-center/components/modules/AssetManagement';
import HerbicideSavings from '../../../command-center/components/modules/HerbicideSavings';

import styles from './CommandCenter.module.scss';

/**
 * CommandCenter is the main component for display command center data.
 *
 * @example
 * <CommandCenter />
 */

const CommandCenter = () => {
  return (
    <div>
      <Navbar />
      <Breadcrumbs />
      <Divider className={styles.divider} />
      <PageTitle />
      <Divider className={styles.divider} />
      <main className={styles.mainContainer}>
        <Savings />
        <Runs />
        <AssetManagement />
        <HerbicideSavings />
      </main>
    </div>
  );
};

export default CommandCenter;
