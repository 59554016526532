import { TReportType } from '../interfaces/report-type';

export interface IReportsData {
  content: string; // Base64 encoded content of the file
  name: string; // The name of the file
  contentType: string; // The content type (e.g., 'pdf')
}

export const downloadFile = (report: IReportsData, type: TReportType) => {
  const blob = new Blob(
    [
      new Uint8Array(
        atob(report.content)
          .split('')
          .map((c) => c.charCodeAt(0))
      )
    ],
    { type: `${report.contentType}` }
  );

  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = `${report.name}.${type}`;
  link.click();
};
