export const CHART_FONT = 'Montserrat, sans-serif';
export const CHART_LEGEND_DIVISION = 5;

export const CHART_LEGEND_COLORS = {
  green: '#459B76',
  blue: '#6DB3E4',
  orange: '#DDA138',
  orangeLight: 'rgba(221, 161, 56, 0.2)',
  graySecondary: '#E9E9E9',
  points: '#2A2A2F',
  background: '#F6F6F6'
};

export const WIDGET_DEFAULT_HEIGHT = 404;

export const CHART_LABELS_DATE_FORMAT = 'MM-dd-yyyy';

export const CHART_LEGEND_SIZE = 10;
