import _ from 'lodash';
import React, { useState, useEffect, useCallback } from 'react';
import toast from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import {
  Box,
  Button,
  Checkbox,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import authService from '../../api-authorization/AuthorizeService';
import styles from './AdminConfigTractorBooms.module.css';
import AddBoomDialog from './AddBoomDialog/AddBoomDialog';
import DeleteBoomDialog from './DeleteBoomDialog/DeleteBoomDialog';

const AdminConfigTractorBooms = () => {
  const [searchParams] = useSearchParams();
  const [tractor, setTractor] = useState({});
  const [booms, setBooms] = useState([]);
  const [fetchedBooms, setFetchedBooms] = useState([]);
  const [boomForDeletion, setBoomForDeletion] = useState(null);
  const [loading, setLoading] = useState(true);
  const [, setError] = useState(false);
  const [addBoomDialogOpen, setIsAddBoomDialogOpen] = useState(false);
  const [deleteBoomDialogOpen, setIsDeleteBoomDialogOpen] = useState(false);

  const getClientId = useCallback(
    () => searchParams.get('clientId'),
    [searchParams]
  );

  const getTractorId = useCallback(
    () => searchParams.get('tractorId'),
    [searchParams]
  );

  const fetchTractor = useCallback(async () => {
    setLoading(true);
    try {
      const clientId = getClientId();
      const tractorId = getTractorId();
      const token = await authService.getAccessToken();
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/admin/tractor?clientId=${clientId}&tractorId=${tractorId}`,
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` }
        }
      );
      const tractor = await response.json();
      setTractor(tractor);
      setLoading(false);
      setError(false);
    } catch (error) {
      setTimeout(() => {
        setError(true);
        setLoading(false);
      }, 1000);
    }
  }, [getClientId, getTractorId]);

  const fetchBooms = useCallback(async () => {
    setLoading(true);
    try {
      const clientId = getClientId();
      const tractorId = getTractorId();
      const token = await authService.getAccessToken();
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/admin/tractors/booms?clientId=${clientId}&tractorId=${tractorId}`,
        {
          headers: !token ? {} : { Authorization: `Bearer ${token}` }
        }
      );
      const booms = (await response.json()).map((n) => ({
        ...n,
        overlap: Math.round(n.overlap * 100)
      }));
      setFetchedBooms(booms);
      setBooms(booms);
      setLoading(false);
      setError(false);
    } catch (error) {
      setTimeout(() => {
        setError(true);
        setLoading(false);
      }, 1000);
    }
  }, [getClientId, getTractorId]);

  useEffect(() => {
    fetchTractor();
    fetchBooms();
  }, [getClientId, getTractorId, fetchTractor, fetchBooms]);

  const onEditBoom = (boomId, fieldName, newValue) => {
    debugger;
    const updatedBooms = booms.map((boom) => {
      let value;
      if (boom.id === boomId) {
        return {
          ...boom,
          [fieldName]: newValue
        };
      }
      return boom;
    });
    setBooms(updatedBooms);
  };

  const hasBoomValueChanged = (boom, fieldName) => {
    const fetchedBoom = fetchedBooms.find(
      (fetchedBoom) => fetchedBoom.id === boom.id
    );
    return fetchedBoom[fieldName] !== boom[fieldName];
  };

  const haveAnyValuesChanged = () => {
    return _.isEqual(fetchedBooms, booms);
  };

  const discardChanges = () => {
    setBooms(fetchedBooms);
  };

  const updateBooms = async () => {
    const token = await authService.getAccessToken();
    debugger;
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/admin/tractors/booms`,
      {
        method: 'POST',
        headers: !token
          ? {}
          : {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        body: JSON.stringify(booms)
      }
      );

      if (response.ok) {
          toast.success('Boom updated successfully!');
      } else {
          toast.error(`${response.status} ${response.statusText}`);
      }
    fetchBooms();
  };

  const openAddBoomDialog = () => {
    setIsAddBoomDialogOpen(true);
  };

  const closeShowAddBoomDialog = () => {
    setIsAddBoomDialogOpen(false);
  };

  const handleAddBoomSuccess = () => {
    setIsAddBoomDialogOpen(false);
    fetchBooms();
  };

  const openDeleteBoomDialog = (boom) => {
    setBoomForDeletion(boom);
    setIsDeleteBoomDialogOpen(true);
  };

  const closeDeleteBoomDialog = () => {
    setBoomForDeletion(null);
    setIsDeleteBoomDialogOpen(false);
  };

  const handleDeleteBoomSuccess = () => {
    closeDeleteBoomDialog();
    fetchBooms();
  };

  return (
    <>
      <div className={styles.container}>
        <Typography variant="h4" sx={{ pb: 1 }}>
          {tractor.name}
        </Typography>
        <Typography variant="h5" sx={{ pb: 1 }}>
          Boom Management
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={2}
          alignItems="center"
        >
          <Box>
            <Typography>
              Displays all the booms on the sprayer.
            </Typography>
          </Box>
          {haveAnyValuesChanged() ? (
            <Button
              variant="outlined"
              disabled={loading}
              startIcon={<AddIcon />}
              onClick={openAddBoomDialog}
            >
              Add Boom
            </Button>
          ) : (
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                variant="outlined"
                startIcon={<UndoIcon />}
                onClick={discardChanges}
              >
                Discard Changes
              </Button>
              <Button
                variant="outlined"
                onClick={updateBooms}
                startIcon={<SaveIcon />}
              >
                Save Changes
              </Button>
            </Box>
          )}
        </Stack>
        <Stack>
          <TableContainer component={Paper} sx={{ my: 1 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Boom Name</TableCell>
                  <TableCell>Is Active</TableCell>
                  <TableCell>Nozzle Count</TableCell>
                  <TableCell>Notes</TableCell>                  
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {booms.map((boom, index) => (
                  <TableRow key={boom.id}>
                    <TableCell>{index + 1}</TableCell>

                    <TableCell>
                      <TextField
                        type="text"
                        InputProps={{ inputProps: { step: 'any' } }}
                        value={boom.name}
                        error={hasBoomValueChanged(boom, 'name')}
                        onChange={(e) =>
                          onEditBoom(
                            boom.id,
                            'name',
                            e.target.value
                          )
                        }
                      />
                    </TableCell>

                    <TableCell>
                    <Checkbox 
                      checked={ boom.isActive} 
                      onChange={(e) =>
                      onEditBoom(
                        boom.id,
                        'isActive',
                        e.target.checked)
                      }
                    />
                      
                    </TableCell>
                      
                    <TableCell>
                      <Typography>
                        {boom.nozzleCount}
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <TextField
                        type="text"
                        multiline
                        rows={4}
                        InputProps={{ inputProps: { step: 'any' } }}
                        value={boom.notes}
                        error={hasBoomValueChanged(boom, 'notes')}
                        onChange={(e) =>
                          onEditBoom(
                            boom.id,
                            'notes',
                            e.target.value
                          )
                        }
                      />
                    </TableCell>

                    <TableCell>
                      <Button
                        variant="outlined"
                        startIcon={<DeleteIcon />}
                        disabled={!haveAnyValuesChanged()}
                        onClick={() => openDeleteBoomDialog(boom)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {addBoomDialogOpen && (
            <AddBoomDialog
              clientId={getClientId()}
              tractorId={getTractorId()}
              open={addBoomDialogOpen}
              onClose={closeShowAddBoomDialog}
              onSuccess={handleAddBoomSuccess}
            />
          )}
          {deleteBoomDialogOpen && (
            <DeleteBoomDialog
              clientId={getClientId()}
              boomId={boomForDeletion.id}
              open={deleteBoomDialogOpen}
              onClose={closeDeleteBoomDialog}
              onSuccess={handleDeleteBoomSuccess}
            />
          )}
        </Stack>
      </div>
    </>
  );
};

export default AdminConfigTractorBooms;
