import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import Pagination from './TablePagination';
import styles from './Table.module.scss';
import clsx from 'clsx';

interface Column {
  id: string | number;
  label: string;
}

interface Data {
  [key: string]: string | number;
}

export interface TableComponentProps {
  columns: Column[];
  rows: Data[];
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}

const TableComponent: React.FC<TableComponentProps> = ({
  columns,
  rows,
  currentPage,
  totalPages,
  onPageChange
}) => {
  const handlePaginationChange = (newPage: number) => {
    onPageChange(newPage);
  };

  return (
    <div className={styles.tableWrapper}>
      <TableContainer className={styles.tableContainer}>
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  className={clsx(
                    { [styles.link]: column.id === 'runUrl' },
                    styles.tableCell
                  )}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, rowIndex) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={rowIndex}>
                {columns.map((column) => {
                  const value = row[column.id];
                  return (
                    <TableCell
                      key={column.id}
                      className={clsx(
                        { [styles.link]: column.id === 'runUrl' },
                        styles.tableCell
                      )}
                    >
                      {column.id === 'runUrl' ? (
                        <IconButton
                          href={String(value)}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={styles.button}
                        >
                          <ArrowOutwardIcon />
                        </IconButton>
                      ) : (
                        value
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onChange={handlePaginationChange}
      />
    </div>
  );
};

export default TableComponent;
