import React from 'react';
import styles from './Runs.module.scss';
import WidgetGroup from '../../WidgetGroup';
import RunsSummary from './RunsSummary';
import RunsInfo from './RunsInfo';

const Runs = () => {
  return (
    <WidgetGroup title="🚜 Runs">
      <div className={styles.container}>
        <RunsSummary />
        <RunsInfo />
      </div>
    </WidgetGroup>
  );
};

export default Runs;
