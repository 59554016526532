import React, { useState } from 'react';
import styles from './PageTitle.module.scss';
import datePickerStyles from '../FilterPanel/FilterPanel.module.scss';
import BackButton from '../BackButton/BackButton';
import Heading from '../typographies/Heading/Heading';
import { Box, ClickAwayListener } from '@mui/material';
import Text from '../typographies/Text/Text';
import DatePickerComponent from '../DatePicker/DatePicker';
import { exportSavingsReportToFile } from '../../utils/file-export-utils';
import { UTCFormat } from '../../utils/dateFormat';
import { DateRange } from 'react-day-picker';
import { dateFormatParams } from '../../constants/dateFormat';

/**
 * PageTitle component needs to show page title with back button.
 *
 * @example
 * <PageTitle />
 */

const PageTitle = () => {
  const [open, setOpen] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const exportSavingsReport = async (value: DateRange | undefined) => {
    handleClose();
    const formattedDateFrom = UTCFormat(value?.from, dateFormatParams);
    const formattedDateTo = UTCFormat(value?.to, dateFormatParams);
    await exportSavingsReportToFile(
      {
        dateFrom: formattedDateFrom,
        dateTo: formattedDateTo
      },
      setLoadingExport
    );
  };

  return (
    <div className={styles.pageTitle}>
      <BackButton />
      <Box className={styles.exportBox}>
        <Heading level={1}>📡 Command Center</Heading>
        <Text
          tag="button"
          onClick={handleClick}
          isLoading={loadingExport}
          className={styles.exportButton}
        >
          <img src="/images/download.svg" alt="Download Icon" />
          Herbicide savings summary report
        </Text>
        <ClickAwayListener onClickAway={handleClose} mouseEvent="onMouseDown">
          <div
            className={
              open
                ? `${datePickerStyles.dateRangeFilter} ${styles.dateRangeFilter}`
                : datePickerStyles.filterClosed
            }
          >
            <DatePickerComponent
              onChange={exportSavingsReport}
              handleClose={handleClose}
            />
          </div>
        </ClickAwayListener>
      </Box>
    </div>
  );
};

export default PageTitle;
