import React, { useState } from 'react';
import toast from 'react-hot-toast';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import authService from '../../../api-authorization/AuthorizeService';

interface IDeleteBoomDialogProps {
  clientId: string;
  boomId: string;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const DeleteBoomDialog: React.FC<IDeleteBoomDialogProps> = ({
  clientId,
  boomId,
  open,
  onClose,
  onSuccess
}) => {
  const [loading, setLoading] = useState(false);

  const deleteNozzle = async () => {
    setLoading(true);
    const token = await authService.getAccessToken();
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/admin/tractors/boom?clientId=${clientId}&boomId=${boomId}`,
      {
        method: 'DELETE',
        headers: !token
          ? {}
          : {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
      }
    );
    if (response.ok) {
      toast.success('Boom deleted successfully!');
      onSuccess();
    } else {
      toast.error(
        `Failed to delete boom.  Ensure there aren't any nozzles associated with this boom.'`
      );
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <DeleteIcon /> Delete Boom
      </DialogTitle>
      <DialogContent>Are you sure you want to delete this boom?</DialogContent>
      <DialogActions>
        <Button variant="outlined" disabled={loading} onClick={onClose}>
          Cancel
        </Button>
        <Button variant="outlined" disabled={loading} onClick={deleteNozzle}>
          Delete Boom
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteBoomDialog;
