import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import authService from '../../../api-authorization/AuthorizeService';

interface IAddBoomDialogProps {
  clientId: string;
  tractorId: string;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

type FormValues = {
  name: string;
  notes: string;
};

const AddBoomDialog: React.FC<IAddBoomDialogProps> = ({
  clientId,
  tractorId,
  open,
  onClose,
  onSuccess
}) => {
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit, register, watch } = useForm<FormValues>();

  const addBoom = async (data: FormValues) => {
    setLoading(true);
    const token = await authService.getAccessToken();
    const body = [
      {
        name: data.name,
        notes: data.notes,
        clientId: clientId,
        tractorId: tractorId
      }
    ];

    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/admin/tractors/booms`,
      {
        method: 'POST',
        headers: !token
          ? {}
          : {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
            },
        body: JSON.stringify(body)
      }
    );
    if (response.ok) {
      toast.success('Nozzles added successfully!');
      onSuccess();
    } else {
      toast.error(`${response.status} ${response.statusText}`);
    }
    setLoading(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <AddIcon />
        Add Boom
      </DialogTitle>
      <form onSubmit={handleSubmit(addBoom)}>
        <DialogContent sx={{ maxWidth: '480px', paddingTop: 0 }}>
          <Typography gutterBottom>Add a new boom to the sprayer.</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <TextField
                    type="text"
                    disabled={loading}
                    InputProps={{ inputProps: { step: 'any' } }}
                    {...field}
                  />
                )}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Notes</FormLabel>
              <Controller
                control={control}
                name="notes"
                render={({ field }) => (
                  <TextField
                    type="text"
                    disabled={loading}
                    multiline
                    rows={4}
                    InputProps={{ inputProps: { step: 'any' } }}
                    {...field}
                  />
                )}
              />
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" disabled={loading} onClick={onClose}>
            Cancel
          </Button>
          <Button variant="outlined" disabled={loading} type="submit">
            Add Boom
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddBoomDialog;
