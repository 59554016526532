import React from 'react';
import { Radar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  ChartData,
  ChartOptions,
  Chart,
  TooltipModel
} from 'chart.js';
import WidgetCard, { IWidgetCardProps } from '../../WidgetCard/WidgetCard';
import styles from './RadarWidget.module.scss';
import {
  CHART_FONT,
  CHART_LEGEND_COLORS,
  WIDGET_DEFAULT_HEIGHT
} from '../../../constants/charts';
import { customTicksPlugin } from './customTicksPlugin';
import { FIXED_PRECISION } from '../../../constants/numbers';

const getOrCreateTooltip = (chart: Chart) => {
  let tooltipEl = chart.canvas.parentNode?.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    const table = document.createElement('table');
    const border = document.createElement('div');

    tooltipEl.classList.add(styles.tooltip);
    border.classList.add(styles.tooltipBorder);

    tooltipEl.appendChild(border);
    tooltipEl.appendChild(table);

    chart.canvas.parentNode?.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const externalTooltipHandler = (context: {
  chart: Chart;
  tooltip: TooltipModel<'radar'>;
}) => {
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = '0';
    return;
  }

  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((b) => b.lines);

    const tableHead = document.createElement('thead');
    tableHead.classList.add(styles.tooltipHeader);

    titleLines.forEach((title) => {
      const tr = document.createElement('tr');
      const th = document.createElement('th');
      const text = document.createTextNode(title);

      th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement('tbody');

    bodyLines.forEach((body) => {
      body.forEach((line, index) => {
        const parts = line.split(':');
        const customLine = {
          text: parts[0]?.trim(),
          amount: parseFloat(
            parseFloat(parts[1]?.trim()).toFixed(FIXED_PRECISION)
          ).toString()
        };

        const tr = document.createElement('tr');
        tr.classList.add(styles.tooltipRow);
        const td = document.createElement('td');

        const amount = document.createTextNode(customLine.amount);
        const amountText = document.createTextNode(customLine.text);
        const amountSpan = document.createElement('span');
        const textSpan = document.createElement('span');
        amountSpan.appendChild(amount);
        textSpan.appendChild(amountText);

        // Apply specific styles based on the index
        if (index === 0) {
          amountSpan.classList.add(styles.tooltipAmountDefault);
        } else if (index === 1) {
          amountSpan.classList.add(styles.tooltipAmountSuccess);
        }

        amountSpan.classList.add(styles.tooltipAmount);
        textSpan.classList.add(styles.tooltipText);

        td.appendChild(amountSpan);
        td.appendChild(textSpan);
        tr.appendChild(td);
        tableBody.appendChild(tr);
      });
    });

    const tableRoot = tooltipEl.querySelector('table');

    while (tableRoot?.firstChild) {
      tableRoot.firstChild.remove();
    }

    tableRoot?.appendChild(tableHead);
    tableRoot?.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  tooltipEl.style.left = positionX + tooltip.caretX + 'px';
  tooltipEl.style.top = positionY + tooltip.caretY + 'px';
  tooltipEl.style.opacity = '1';
};

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  customTicksPlugin
);

interface Props extends Omit<IWidgetCardProps, 'children'> {
  /**
   * Data for the radar chart.
   * Should follow the Chart.js data structure for radar charts.
   */
  data: ChartData<'radar'>;
  /**
   * Chart.js options for customizing the radar chart.
   */
  options?: ChartOptions<'radar'>;
  /**
   * Chart height.
   * @default 404px
   */
  height?: number;
}

const RadarWidget: React.FC<Props> = ({
  data,
  options,
  height = WIDGET_DEFAULT_HEIGHT,
  ...widgetProps
}) => {
  const isDataExist = data.datasets.every((dataset) => dataset.data?.length);
  return (
    <WidgetCard isEmpty={!isDataExist} {...widgetProps}>
      <div style={{ height }} className={styles.container}>
        <Radar
          data={data}
          options={{
            ...options,
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              r: {
                grid: {
                  color: CHART_LEGEND_COLORS.graySecondary
                },
                angleLines: {
                  color: CHART_LEGEND_COLORS.graySecondary
                },
                ticks: {
                  display: true,
                  stepSize: 200
                },
                pointLabels: {
                  padding: 16,
                  color: CHART_LEGEND_COLORS.points, // Color of labels (Block 1, Block 2, etc.)
                  font: {
                    family: CHART_FONT,
                    size: 11
                  }
                }
              }
            },
            plugins: {
              legend: {
                display: false // Disable legend
              },
              tooltip: {
                enabled: false,
                external: externalTooltipHandler,
                ...options?.plugins?.tooltip
              }
            },
            elements: {
              line: {
                borderWidth: 1, // Line thickness
                borderColor: CHART_LEGEND_COLORS.orange, // Line color
                backgroundColor: CHART_LEGEND_COLORS.orangeLight // Fill color
              },
              point: {
                backgroundColor: CHART_LEGEND_COLORS.orange, // Point color
                hoverBorderWidth: 8,
                hoverRadius: 12
              }
            }
          }}
        />
      </div>
    </WidgetCard>
  );
};

export default RadarWidget;
