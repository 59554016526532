import React from 'react';
import WidgetCard, { IWidgetCardProps } from '../../WidgetCard/WidgetCard';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  SubTitle,
  Legend,
  ChartData,
  ChartOptions
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import {
  CHART_FONT,
  CHART_LEGEND_COLORS,
  CHART_LEGEND_DIVISION,
  CHART_LEGEND_SIZE,
  WIDGET_DEFAULT_HEIGHT
} from '../../../constants/charts';
import styles from './LineChartWidget.module.scss';

// Register required Chart.js modules
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  SubTitle,
  Legend
);

interface Props extends Omit<IWidgetCardProps, 'children'> {
  /**
   * Data for the bar chart.
   * Should follow the Chart.js data structure for bar charts.
   */
  data: ChartData<'line'>;
  /**
   * Chart.js options for customizing the bar chart.
   */
  options?: ChartOptions<'line'>;
  /**
   * Chart.js subtitle for display subtitle in the chart.
   */
  subtitle?: string;
  /**
   * Chart height.
   * @default 404px
   */
  height?: number;
}

/**
 * A reusable widget component that renders a line chart using Chart.js and react-chartjs-2.
 */
const LineChartWidget: React.FC<Props> = ({
  data,
  options,
  subtitle,
  height = WIDGET_DEFAULT_HEIGHT,
  ...widgetProps
}) => {
  const isDataExist = data.datasets.every((dataset) => dataset.data?.length);
  return (
    <WidgetCard
      className={styles.container}
      isEmpty={!isDataExist}
      {...widgetProps}
    >
      <div style={{ height }}>
        <Line
          data={data}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: data.datasets.length > 1,
                position: 'top' as const,
                labels: {
                  font: {
                    family: CHART_FONT
                  },
                  boxWidth: CHART_LEGEND_SIZE,
                  boxHeight: CHART_LEGEND_SIZE,
                  usePointStyle: true,
                  pointStyle: 'rectRounded'
                }
              },
              subtitle: {
                display: !!subtitle,
                position: 'left',
                align: 'center',
                text: subtitle
              }
            },
            scales: {
              x: {
                offset: true, // show items on the center
                beginAtZero: true,
                ticks: {
                  font: {
                    family: CHART_FONT,
                    size: 11
                  }
                },
                grid: {
                  drawOnChartArea: false
                }
              },
              y: {
                beginAtZero: true,
                stacked: false,
                grid: {
                  drawTicks: true, // Draw grid lines at ticks
                  drawOnChartArea: true, // Draw grid lines across the chart area
                  color: (context) => {
                    return context.tick.value % CHART_LEGEND_DIVISION === 0
                      ? CHART_LEGEND_COLORS.graySecondary
                      : undefined;
                  }
                },
                ticks: {
                  font: {
                    size: 11,
                    family: CHART_FONT
                  },
                  callback: function (value) {
                    return Number(value) % CHART_LEGEND_DIVISION === 0
                      ? value
                      : '';
                  }
                }
              }
            },
            elements: {
              line: {
                borderWidth: 2,
                borderColor: CHART_LEGEND_COLORS.orange
              }
            },
            layout: {
              padding: 16
            },
            ...options
          }}
        />
      </div>
    </WidgetCard>
  );
};

export default LineChartWidget;
