import React from 'react';
import styles from '../FilterPanel.module.scss';
import DateFilterOption from '../Options/DateFilterOption';
import useSWR from 'swr';
import { LOCATIONS_API_ENDPOINTS } from '../../../constants/api';
import { fetchBlocks } from '../../../utils/api-utils';
import { DateRange } from 'react-day-picker';
import FilterOption from '../Options/FilterOption';
import { ISavingsPerBlockProperties } from './interface';
import { BlockItem } from '../../../interfaces/blocks';

/**
 * Filter component needs to filter and export data.
 *
 * @example
 * <Filter
 *    open={isOpen}
 *    properties={properties}
 *    setProperties={setProperties}
 *  />
 */

interface ISavingsPerBlockFilterProps {
  open: boolean;
  properties: ISavingsPerBlockProperties;
  setProperties: React.Dispatch<
    React.SetStateAction<ISavingsPerBlockProperties>
  >;
}

const SavingsPerBlockFilter: React.FC<ISavingsPerBlockFilterProps> = ({
  open,
  properties,
  setProperties
}) => {
  const { data, isLoading } = useSWR(`${LOCATIONS_API_ENDPOINTS.blocks}`, () =>
    fetchBlocks()
  );

  const handleDateFilterChange = (value: DateRange | undefined) => {
    setProperties({ ...properties, date: value });
  };

  const handleBlockChange = (value: number[]) => {
    if (value) {
      const selectedBlocks: BlockItem[] = value
        .map((val: number) => {
          const selectedBlock = data?.find((blockItem) => blockItem.id === val);
          return selectedBlock;
        })
        .filter(
          (selectedBlock): selectedBlock is BlockItem =>
            selectedBlock !== undefined
        );
      setProperties({ ...properties, block: selectedBlocks });
    } else {
      setProperties({ ...properties, block: [] });
    }
  };

  return (
    <div className={open ? styles.filter : styles.filterClosed}>
      <DateFilterOption
        value={properties.date}
        onChange={handleDateFilterChange}
      />
      <FilterOption
        iconPath="/images/plant.svg"
        title="Block"
        options={data || []}
        isLoading={isLoading}
        value={properties?.block?.map((block: BlockItem) => block.id)}
        onChange={handleBlockChange}
        multiple
      />
    </div>
  );
};

export default SavingsPerBlockFilter;
