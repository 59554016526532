import React, { useState } from 'react';
import LineChartWidget from '../../widgets/LineChartWidget';
import { IProperties } from '../../FilterPanel/interface';
import {
  formatChartLabelDate,
  getDefaultDateFilterRange
} from '../../../utils/dateFormat';
import RunsFilterPanel from '../../FilterPanel/RunsFilterPanel/RunsFilterPanel';
import useSavingsAllBlocks from '../../../hooks/useSavingsAllBlocks';
import { EReportName } from '../../../constants/reports';

const SavingsAllBlocks = () => {
  const [properties, setProperties] = useState<IProperties>({
    date: getDefaultDateFilterRange()
  });

  const { data, isLoading, error } = useSavingsAllBlocks(properties);

  const totalPesticideSavingAmountGallons: number[] | undefined = data?.map(
    (el) => el.totalPesticideSavingAmountGallons
  );

  return (
    <LineChartWidget
      error={error}
      title={EReportName.HerbicideSavings}
      icon="📊"
      description="Herbicide saved trend (gal)"
      subtitle="gal"
      isLoading={isLoading}
      properties={properties}
      setProperties={setProperties}
      isExport
      filter={
        <RunsFilterPanel
          properties={properties}
          setProperties={setProperties}
        />
      }
      data={{
        labels: data?.map(formatChartLabelDate),
        datasets: [
          {
            data: totalPesticideSavingAmountGallons || []
          }
        ]
      }}
    />
  );
};

export default SavingsAllBlocks;
