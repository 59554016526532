import useSWR from 'swr';
import { ISavingsProperties } from '../components/FilterPanel/SavingsFilterPanel/interface';
import { UTCFormat } from '../utils/dateFormat';
import { CC_API_ENDPOINTS } from '../constants/api';
import { createQueryString, fetchSprayerInfo } from '../utils/api-utils';
import { dateFormatParams } from '../constants/dateFormat';

const useSprayerInfo = ({ date }: ISavingsProperties) => {
  const formattedDateFrom = UTCFormat(date?.from, dateFormatParams);
  const formattedDateTo = UTCFormat(date?.to, dateFormatParams);

  const sprayerInfoParams = {
    dateFrom: formattedDateFrom,
    dateTo: formattedDateTo
  };

  const { data, isLoading, error } = useSWR(
    `${CC_API_ENDPOINTS.sprayerInfo}?${createQueryString(sprayerInfoParams)}`,
    () => fetchSprayerInfo(sprayerInfoParams)
  );

  const { sprayedInfo } = data || {};

  return {
    data: sprayedInfo,
    isLoading,
    error
  };
};

export default useSprayerInfo;
