import * as React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import styles from "./AlertDialog.module.css";

export default function AlertDialog(props) {
    const { open, onClose, buttonClick, title, content, buttons } = props;

    return (
        <React.Fragment>
            <Dialog open={open} onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{ sx: { borderRadius: "15px" } }}
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {buttons.map((button) => {
                        return (
                            <button className={styles.button + " " + button.css_class}
                                    onClick={() => { buttonClick(button.id) }}>{button.text}</button>
                        );
                    })}
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
