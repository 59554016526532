/* eslint-disable @typescript-eslint/no-explicit-any */
import html2canvas from 'html2canvas';
import { fetchSavingsReports } from './api-utils';
import { downloadFile } from './file-utils';
import { IReportsParams } from '../interfaces/reports-interfaces';
import { ISavingsReportsParams } from '../interfaces/savings-reports-interfaces';
import toast from 'react-hot-toast';

// Utility function to handle common logic for both single and multiple elements
const exportToFile = async (
  params: IReportsParams,
  name: string,
  elements: (HTMLElement | null)[],
  handleLoadingExport: (isLoading: boolean) => void,
  fetchReports: (params: IReportsParams, formData: FormData) => Promise<any>
) => {
  if (elements.length > 0) {
    const style = document.createElement('style');
    document.head.appendChild(style);
    style.sheet?.insertRule(
      'body > div:last-child img { display: inline-block; }'
    );

    try {
      handleLoadingExport(true);
      const images = await Promise.all(
        elements.map(async (element) => {
          if (element) {
            const canvas = await html2canvas(element);
            const imageUrl = canvas.toDataURL('image/png');
            const imageBlob = await (await fetch(imageUrl)).blob();
            return imageBlob;
          }
          return null;
        })
      );

      const formData = new FormData();
      images
        .filter((imageBlob): imageBlob is Blob => imageBlob !== null)
        .forEach((imageBlob, index) => {
          formData.append(
            'files',
            imageBlob,
            `exported_image_${index + 1}.png`
          );
        });
      formData.append('name', name);
      const response = await fetchReports(params, formData);
      toast.success('Report successfully exported');
      downloadFile(response, params.type);
    } catch (error) {
      toast.error('Error exporting elements');
      console.error('Error exporting elements:', error);
    } finally {
      style.remove();
      handleLoadingExport(false);
    }
  }
};

// Export for single element
export const exportToFileFromElement = async (
  params: IReportsParams,
  name: string,
  elementRef: React.RefObject<HTMLElement>,
  handleLoadingExport: (isLoading: boolean) => void,
  fetchReports: (params: IReportsParams, formData: FormData) => Promise<any>
) => {
  await exportToFile(
    params,
    name,
    [elementRef.current],
    handleLoadingExport,
    fetchReports
  );
};

// Export for multiple elements
export const exportToFileFromElements = async (
  params: IReportsParams,
  name: string,
  elementIds: string[],
  handleLoadingExport: (isLoading: boolean) => void,
  fetchReports: (params: IReportsParams, formData: FormData) => Promise<any>
) => {
  const refs = elementIds.map(
    (id) => document.querySelector(`#${id}`) as HTMLElement | null
  );
  await exportToFile(params, name, refs, handleLoadingExport, fetchReports);
};

export const exportSavingsReportToFile = async (
  params: ISavingsReportsParams,
  handleLoadingExport: (isLoading: boolean) => void
) => {
  try {
    handleLoadingExport(true);
    const response = await fetchSavingsReports(params);
    downloadFile(response, 'xlsx');
    toast.success('Report successfully exported');
  } catch (error) {
    toast.error('Error exporting elements');
    console.error('Error exporting elements:', error);
  } finally {
    handleLoadingExport(false);
  }
};
