import React, { useEffect, useState } from 'react';
import datePickerStyles from '../FilterPanel/FilterPanel.module.scss';
import { DateRange, DayPicker, getDefaultClassNames } from 'react-day-picker';
import { getDefaultDateFilterRange } from '../../utils/dateFormat';
import Text from '../typographies/Text/Text';

/**
 * DatePickerComponent component needs to display DatePicker and choose daterange.
 *
 * @example
 * <DatePickerComponent />
 */

interface IDatePickerProps {
  handleClose: () => void;
  onChange: (selected: DateRange | undefined) => void;
  value?: DateRange | undefined;
}

const DatePickerComponent: React.FC<IDatePickerProps> = ({
  handleClose,
  onChange,
  value
}) => {
  const defaultClassNames = getDefaultClassNames();

  const [selected, setSelected] = useState<DateRange | undefined>(
    value || getDefaultDateFilterRange()
  );

  useEffect(() => {
    if (!value) return;
    setSelected(value);
  }, [value]);

  const handleApply = () => {
    onChange(selected);
  };

  return (
    <>
      <DayPicker
        mode="range"
        weekStartsOn={1}
        selected={selected}
        onSelect={setSelected}
        classNames={{
          selected: `rdp-custom-selected`,
          root: `${defaultClassNames.root} ${datePickerStyles.root}`
        }}
      />
      <div className={datePickerStyles.buttonGroup}>
        <Text type="button" tag="button" onClick={handleClose}>
          Cancel
        </Text>
        <Text
          type="button"
          tag="button"
          className={datePickerStyles.apply}
          onClick={handleApply}
        >
          Apply
        </Text>
      </div>
    </>
  );
};

export default DatePickerComponent;
