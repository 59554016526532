/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef } from 'react';
import clsx from 'clsx';
import { Skeleton } from '@mui/material';
import styles from './WidgetCard.module.scss';
import filterPanelStyles from '../FilterPanel/FilterPanel.module.scss';
import Text from '../typographies/Text/Text';
import Heading from '../typographies/Heading/Heading';
import FiltrationChip from '../FilterPanel/FiltrationChip';
import ExportButton from '../ExportButton';
import { appliedFilters } from '../../utils/filter-utils';
import { BlockItem } from '../../interfaces/blocks';
import EmptyState from './EmptyState';
import ErrorState from './ErrorState';
import { ErrorType } from '../../interfaces/error-type';

/**
 * WidgetCard component needs to reuse and show widget data.
 *
 * @example
 *  <WidgetCard title="Your title" description="Your descr">
 *    Some children data
 *  </WidgetCard>
 */

//TODO: remove any type when properties and setProperties will change
export interface IWidgetCardProps {
  children: React.ReactNode;
  title: string;
  icon?: string;
  description: string;
  properties?: any;
  setProperties?: React.Dispatch<React.SetStateAction<any>>;
  className?: string;
  id?: string;
  isLoading?: boolean;
  filter?: React.ReactNode;
  isExport?: boolean;
  isEmpty?: boolean;
  error?: ErrorType;
}

const WidgetCard: React.FC<IWidgetCardProps> = ({
  children,
  title,
  icon = '',
  description,
  properties,
  setProperties,
  className,
  id,
  filter,
  isExport = false,
  isLoading = false,
  isEmpty = false,
  error
}) => {
  const printRef = useRef<HTMLDivElement | null>(null);

  const clearProperty = (propertyName: string) => {
    setProperties?.((prev: any) => {
      return {
        ...prev,
        [propertyName]: []
      };
    });
  };
  const onDeleteItem = (propertyName: string, itemId: number) => {
    setProperties?.((prev: any) => {
      const currentProperty = prev[propertyName];
      if (Array.isArray(currentProperty)) {
        return {
          ...prev,
          [propertyName]: currentProperty.filter(
            (item: BlockItem) => item.id !== itemId
          )
        };
      }
      return prev;
    });
  };

  const filters = properties && appliedFilters(properties);

  return isLoading ? (
    <Skeleton className={styles.widgetCard} variant="rounded" />
  ) : (
    <div id={id} className={clsx(styles.widgetCard, className)}>
      <div className={styles.header}>
        <Heading level={3} className={styles.title}>
          {icon} {title}
          <div className={filterPanelStyles.filterButtonGroupWrapper}>
            <div className={filterPanelStyles.filterPanel}>
              {filter}
              {isExport ? (
                <ExportButton
                  printRef={printRef}
                  name={title}
                  properties={properties}
                />
              ) : null}
            </div>
          </div>
        </Heading>
        <Text type="label" className={styles.description}>
          {description}
        </Text>
      </div>
      {filters?.length && clearProperty ? (
        <div className={styles.filtrationChipWrapper}>
          {filters.map(([key, value]: any) => (
            <FiltrationChip
              key={key}
              propertyName={key}
              filtration={value}
              onDelete={key !== 'date' ? () => clearProperty(key) : undefined}
              onDeleteItem={onDeleteItem}
            />
          ))}
        </div>
      ) : null}
      {isEmpty ? (
        <EmptyState />
      ) : error ? (
        <ErrorState error={error} />
      ) : (
        <div ref={printRef}>{children}</div>
      )}
    </div>
  );
};

export default WidgetCard;
