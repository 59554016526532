import React, { useState } from 'react';
import WidgetGroup from '../../WidgetGroup';
import TotalNumberWidget from '../../widgets/TotalNumberWidget';

import styles from './Savings.module.scss';
import SavingsFilterPanel from '../../FilterPanel/SavingsFilterPanel/SavingsFilterPanel';
import LineChartWidget from '../../widgets/LineChartWidget';
import { ISavingsProperties } from '../../FilterPanel/SavingsFilterPanel/interface';
import useSavings from '../../../hooks/useSavings';
import { FIXED_PRECISION } from '../../../constants/numbers';
import {
  formatChartLabelDate,
  getDefaultDateFilterRange
} from '../../../utils/dateFormat';
import { WIDGETS } from '../../../constants/savings';
import TotalPercentageWithAmountWidget from '../../widgets/TotalPercentageWithAmountWidget';
import { CHART_LEGEND_COLORS } from '../../../constants/charts';

const Savings: React.FC = () => {
  const [properties, setProperties] = useState<ISavingsProperties>({
    date: getDefaultDateFilterRange(),
    block: undefined
  });

  const { data, isLoading, error } = useSavings(properties);

  const pesticideUsagePesticideUsedAmountGallons = data?.pesticideUsage.map(
    (el) => el.pesticideUsedAmountGallons
  );
  const pesticideBroadcastAmountGallons = data?.pesticideUsage.map(
    (el) => el.pesticideBroadcastAmountGallons
  );
  const pesticideSavingsPesticideUsedAmountGallons =
    data?.pesticideSavings?.map((el) => el.pesticideSavedAmountGallons);

  return (
    <WidgetGroup title="💰 Savings">
      <SavingsFilterPanel
        properties={properties}
        setProperties={setProperties}
      />
      <section className={styles.row}>
        <TotalPercentageWithAmountWidget
          error={error}
          id={WIDGETS.SAVINGS.id}
          isLoading={isLoading}
          title={WIDGETS.SAVINGS.title}
          description={WIDGETS.SAVINGS.description}
          data={{
            percentage: parseFloat(
              data?.averagePesticideSavedAmountPercentage?.toFixed(
                FIXED_PRECISION
              ) ?? ''
            ),
            amount: parseFloat(
              data?.totalPesticideSavedAmountGallons?.toFixed(
                FIXED_PRECISION
              ) ?? ''
            ),
            label: 'Gallons'
          }}
        />
        <TotalNumberWidget
          id={WIDGETS.SPRAYED.id}
          error={error}
          isLoading={isLoading}
          title={WIDGETS.SPRAYED.title}
          description={WIDGETS.SPRAYED.description}
          data={{
            value: parseFloat(
              data?.totalSprayedAreaAcres?.toFixed(FIXED_PRECISION) ?? ''
            ),
            label: 'Acres'
          }}
        />
      </section>
      <div className={styles.row}>
        <LineChartWidget
          id={WIDGETS.USAGE.id}
          error={error}
          isLoading={isLoading}
          data={{
            labels: data?.pesticideUsage?.map(formatChartLabelDate),
            datasets: [
              {
                label: 'Herbicide usage',
                data: pesticideUsagePesticideUsedAmountGallons || [],
                backgroundColor: CHART_LEGEND_COLORS.orange,
                borderColor: CHART_LEGEND_COLORS.orange
              },
              {
                label: 'Broadcast amount',
                data: pesticideBroadcastAmountGallons || [],
                backgroundColor: CHART_LEGEND_COLORS.blue,
                borderColor: CHART_LEGEND_COLORS.blue
              }
            ]
          }}
          title={WIDGETS.USAGE.title}
          subtitle={WIDGETS.USAGE.subtitle}
          description={WIDGETS.USAGE.description}
        />
        <LineChartWidget
          id={WIDGETS.SAVINGS_DYNAMICS.id}
          error={error}
          isLoading={isLoading}
          data={{
            labels: data?.pesticideSavings?.map(formatChartLabelDate),
            datasets: [
              {
                data: pesticideSavingsPesticideUsedAmountGallons || []
              }
            ]
          }}
          title={WIDGETS.SAVINGS_DYNAMICS.title}
          subtitle={WIDGETS.SAVINGS_DYNAMICS.subtitle}
          description={WIDGETS.SAVINGS_DYNAMICS.description}
        />
      </div>
    </WidgetGroup>
  );
};

export default Savings;
