import React, { useState } from 'react';
import BarChartWidget from '../../widgets/BarChartWidget';
import {
  formatChartLabelDate,
  getDefaultDateFilterRange
} from '../../../utils/dateFormat';
import { IProperties } from '../../FilterPanel/RunsFilterPanel/interface';
import RunsFilterPanel from '../../FilterPanel/RunsFilterPanel/RunsFilterPanel';
import useRunsInfo from '../../../hooks/useRunsInfo';
import { CHART_LEGEND_COLORS } from '../../../constants/charts';
import { EReportName } from '../../../constants/reports';

const RunsInfo = () => {
  const [properties, setProperties] = useState<IProperties>({
    date: getDefaultDateFilterRange()
  });

  const { data, isLoading, error } = useRunsInfo(properties);

  const totalDayRunsAmount: number[] | undefined = data?.map(
    (el) => el.totalDayRunsAmount
  );

  const totalPesticideSavedGallons: number[] | undefined = data?.map(
    (el) => el.totalPesticideSavedGallons
  );

  const totalPesticideSprayedGallons: number[] | undefined = data?.map(
    (el) => el.totalPesticideSprayedGallons
  );

  return (
    <BarChartWidget
      error={error}
      title={EReportName.RunsInfo}
      icon="📊"
      description="Shows data for the runs, herbicide sprayed, and herbicide saved."
      subtitle="gal"
      isLoading={isLoading}
      properties={properties}
      setProperties={setProperties}
      isExport
      filter={
        <RunsFilterPanel
          properties={properties}
          setProperties={setProperties}
        />
      }
      data={{
        labels: data?.map(formatChartLabelDate),
        datasets: [
          {
            label: 'Runs', // First bar for each day
            data: totalDayRunsAmount || [],
            backgroundColor: CHART_LEGEND_COLORS.orange
          },
          {
            label: 'Herbicide sprayed', // Second bar for each day
            data: totalPesticideSprayedGallons || [],
            backgroundColor: CHART_LEGEND_COLORS.blue
          },
          {
            label: 'Herbicide saved', // Third bar for each day
            data: totalPesticideSavedGallons || [],
            backgroundColor: CHART_LEGEND_COLORS.green
          }
        ]
      }}
    />
  );
};

export default RunsInfo;
